import { z } from 'zod';

export const SPORTS = ['Tennis', 'Football'] as const;

const sportSchema = z.enum(SPORTS);

const sportEventSchema = z.object({
  id: z
    .string({ required_error: 'Event id is required' })
    .min(1, { message: 'Must be 1 or more characters long' }),
  name: z
    .string({ required_error: 'Event name is required' })
    .min(3, { message: 'Must be 3 or more characters long' }),
  icon: z.optional(z.string()),
});

const sportEventsSchema = z.object({
  sport: sportSchema,
  events: z.array(sportEventSchema),
});

const sequenceTemplateEventItemSchema = z.object({
  projectId: z
    .string({ required_error: 'Project id is required' })
    .min(1, { message: 'Must be 1 or more characters long' }),
  screenId: z
    .string({ required_error: 'Screen id is required' })
    .min(1, { message: 'Must be 1 or more characters long' }),
  designId: z
    .string({ required_error: 'Design id is required' })
    .min(1, { message: 'Must be 1 or more characters long' }),
  attr: z.record(z.any()),
});

export const sequenceTemplateSchema = z.object({
  id: z
    .string({ required_error: 'Template id is required' })
    .min(1, { message: 'Must be 1 or more characters long' }),
  name: z
    .string({ required_error: 'Template name is required' })
    .min(3, { message: 'Must be 3 or more characters long' }),
  sport: sportSchema,
  events: z.optional(
    z.record(z.string(), z.array(sequenceTemplateEventItemSchema)),
  ),
});

export type Sport = z.infer<typeof sportSchema>;
export type SportEvent = z.infer<typeof sportEventSchema>;
export type SportEvents = z.infer<typeof sportEventsSchema>;
export type SequenceTemplateEventItem = z.infer<
  typeof sequenceTemplateEventItemSchema
>;
export type SequenceTemplate = z.infer<typeof sequenceTemplateSchema>;

import { ManualDesignSelector } from '@/components/ManualDesignSelector/ManualDesignSelector';
import { PageHeader } from '@/components/PageHeader/PageHeader';
import { PlayerVsFlags } from '@/components/PlayerVsFlags/PlayerVsFlags';
import { PreviewScene } from '@/components/PreviewScene/PreviewScene';
import { SceneButton } from '@/components/SceneButton/SceneButton';
import { SoccerEventStatus } from '@/components/Soccer/SoccerEventStatus/SoccerEventStatus';
import { SportSettings } from '@/components/SportSettings/SportSettings';
import { ROUTES } from '@/router/constants';
import { useStore } from '@/store/Store';
import { MccChangeDesignData, MccFixtureInfo } from '@/types/mcc';
import { MCSoccerEvent } from '@/types/soccer';
import { apiURL, fetchData } from '@/utils/fetchData';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import { Breadcrumbs, Divider, Link, Paper, Stack, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

type Props = {
    event: MCSoccerEvent;
}

export const SoccerEvent: React.FC<Props> = ({ event }) => {

    const theme = useTheme();
    const { token } = useStore(useShallow((state) => ({
        token: state.token,
    })));
    const [mccData, setMccData] = useState<MccFixtureInfo | undefined>(undefined);

    const changeDesign = (data: MccChangeDesignData) => {
        fetchData({
            token,
            url: apiURL(`/soccer/play/${event.id}/design`),
            method: 'POST',
            data,
        }).then(() => {
            enqueueSnackbar('Design changed', { variant: 'success' });
        }).catch((err) => {
            enqueueSnackbar(err.message, { variant: 'error' });
        });
    }

    useEffect(() => {
        const load = async () =>
            await fetchData<MccFixtureInfo>({
                token,
                url: apiURL(
                    `/soccer/play/${event.id}`,
                ),
            }).catch((err) => {
                enqueueSnackbar(err.message, { variant: 'error' });
            });
        load().then((data) => setMccData(data || undefined));
    }, [event, token]);

    return <Stack sx={{ height: '100%', overflow: { md: 'none', xl: 'hidden' } }}>
        <PageHeader title='Soccer' isLoading={false} />

        <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 2 }}>
            <Link underline='hover' to={ROUTES.SOCCER_EVENTS} component={RouterLink}>
                Soccer Events
            </Link>
            <Link underline='none'>
                {event.name}
            </Link>
        </Breadcrumbs>

        <Divider orientation='horizontal' sx={{ mb: 2 }} />
        <Stack sx={{ gap: 2 }} direction={{ md: 'column', xl: 'row' }}>
            <Stack direction='row' sx={{ height: '100%', gap: 2, flex: 1 }} alignItems='center'>
                <SoccerEventStatus event={event} variant='full' />
                <Divider orientation='vertical' sx={{ height: '100%' }} />
                <Typography variant='h3'>{event.name}</Typography>
            </Stack>
            {event.raw.ddeEvent && <Stack direction='row' sx={{ gap: 2, height: '100%' }}>
                <Divider orientation='vertical' sx={{ height: '100%' }} />
                <Box>
                    <Typography variant='h6'>{event.raw.ddeEvent.collectionStatus}</Typography>
                    <Typography variant='caption' color={theme.palette.info.light}>COLLECTION STATUS</Typography>
                </Box>
                <Divider orientation='vertical' sx={{ height: '100%' }} />
                <Box>
                    <Typography variant='h6'>{event.raw.ddeEvent.venue.name}</Typography>
                    <Typography variant='caption' color={theme.palette.info.light}>{event.raw.ddeEvent.startDateUTC}</Typography>
                </Box>
            </Stack>}
        </Stack>
        <Divider orientation='horizontal' sx={{ mt: 2, mb: 2 }} />

        <Stack spacing={2} sx={{ mb: 2, flex: 1, overflow: { md: 'none', xl: 'hidden' } }} direction={{ md: 'column', xl: 'row' }} >
            <Stack sx={{ flex: 1, height: { md: '50vh', xl: 'auto' }, alignItems: { md: 'stretch', xl: 'end' } }} gap={1}>
                {mccData ? <><PreviewScene streamId={event.streamId} mccData={mccData} />
                    <Stack direction={'row'} gap={1}>
                        <SceneButton mccData={mccData} />
                        <SceneButton mccData={mccData} eventId={event.id} streamId={event.streamId} />
                    </Stack>
                </> : <>
                    <Typography variant='h5'>No Preview</Typography>
                </>}
            </Stack>
            <Paper sx={{ p: 2, height: '100%', overflowY: 'auto' }}>
                <Stack>
                    <Stack direction='row' gap={1} alignItems='center' sx={{ mb: 2 }}>
                        <SettingsBrightnessIcon color='primary' />
                        <Typography variant='h5'>Manual Design</Typography>
                    </Stack>
                    <ManualDesignSelector changeDesign={changeDesign} />
                    <Divider orientation='horizontal' sx={{ mt: 3, mb: 3 }} />
                    <Stack direction='row' gap={1} alignItems='center' sx={{ mb: 2 }}>
                        <SettingsIcon color='primary' />
                        <Typography variant='h5'>Fixture Settings</Typography>
                    </Stack>
                    <SportSettings soccerEvent={event} />
                </Stack>
            </Paper>
        </Stack>
        <PlayerVsFlags soccer={event} />
    </Stack>
};

import { Store, useStore } from "@/store/Store";
import { MccDesignVariableType, MccVariableType, ProjectColor } from "@/types/mcc";
import ConstructionIcon from '@mui/icons-material/Construction';
import { Stack, TextField, Typography } from "@mui/material";
import { useMemo } from "react";
import { v4 as randomUUID } from 'uuid';
import { useShallow } from 'zustand/react/shallow';

type Props = {
    projectId: string;
    screenId: string;
    designId: string;
    values: Record<string, MccDesignVariableType> | undefined;
    onChange: (variable: { id: string, value: MccDesignVariableType }) => void;
}

const createColor = (color: string): ProjectColor => {
    const rnd = randomUUID();
    return {
        _id: rnd,
        color,
        createdAt: new Date().toISOString(),
        name: rnd,
        projectId: rnd,
        updatedAt: new Date().toISOString(),
    }
}

const selector = (state: Store) => ({
    screens: state.screens,
});

export const MCDesignVariables: React.FC<Props> = ({ projectId, screenId, designId, values, onChange }) => {

    const { screens } = useStore(useShallow(selector));

    const variables = useMemo(() => {
        if (!screens || !screens.has(projectId)) return undefined;
        const screen = screens.get(projectId)!.find((s) => s._id === screenId);
        if (!screen) return undefined;
        const design = screen.designs.find((d) => d._id === designId);
        if (!design) return undefined;
        return design.variables;
    }, [screens, projectId, screenId, designId]);

    if (!variables) return <></>

    return <>

        <Stack direction="row" alignItems="center" gap={1} sx={{ mb: 2 }}>
            <ConstructionIcon />
            <Typography variant='h6' gutterBottom>Variables</Typography>
        </Stack>
        <Stack gap={1}>
            {variables.map((variable) => {
                const val = values && values[variable.id] ? values[variable.id] : variable.value;
                switch (variable.type) {
                    case MccVariableType.Number:
                        return <TextField
                            key={`variable-${variable.id}`}
                            type="number"
                            label={variable.name}
                            variant='outlined'
                            value={val}
                            size="small"
                            onChange={(e) => onChange({ id: variable.id, value: Number(e.target.value) })}
                        />
                    case MccVariableType.ProjectColor:
                        return <TextField
                            key={`variable-${variable.id}`}
                            label={variable.name}
                            variant='outlined'
                            value={(val as ProjectColor).color}
                            size="small"
                            onChange={(e) => onChange({ id: variable.id, value: createColor(e.target.value) })}
                        />

                    default:
                        return (
                            <TextField
                                key={`variable-${variable.id}`}
                                label={variable.name}
                                variant='outlined'
                                value={val}
                                size="small"
                                onChange={(e) => onChange({ id: variable.id, value: e.target.value })}
                            />
                        );
                };

            })}
        </Stack>
    </>
}
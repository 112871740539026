import { DDETennisLiveEvent } from '@/types/dde';
import { Button, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  liveEvents: DDETennisLiveEvent[];
};

export const LiveEventsTableView: React.FC<Props> = ({ liveEvents }) => {
  const navigate = useNavigate();

  const handleSelection = (model: GridRowSelectionModel) => {
    const event = liveEvents.find((event) => event.eventId === model[0]);
    navigate(`/tournaments/${event?.tournamentId}/competitions/${event?.competitionId}/events/${event?.eventId}`);
  };

  const events = React.useMemo(() => {
    return liveEvents.map((event) => ({
      ...event,
      id: event.eventId,
    }));
  }, [liveEvents]);

  const columns: GridColDef<DDETennisLiveEvent>[] = [
    { field: 'tournamentName', headerName: 'Tournament', flex: 0.5 },
    { field: 'matchDescription', headerName: 'Match', flex: 0.5 },
    {
      field: 'city', headerName: 'Actions', flex: 0.55, headerAlign: 'right', align: 'right',
      renderCell: (params) => {
        const sceneURL = `${import.meta.env.VITE_MCC_URL}/${params.row.sceneURL}`;

        const handleCopyUrl = (ev: React.MouseEvent) => {
          ev.stopPropagation();
          navigator.clipboard.writeText(sceneURL);
          enqueueSnackbar('Copied to clipboard', { variant: 'success' });
        }

        const handleOpenScene = (ev: React.MouseEvent) => {
          ev.stopPropagation();
        }

        return <>
          <Stack direction='row'>
            <Button color='info' size='small' onClick={handleCopyUrl} >Copy Url</Button>
            <Button color='info' size='small' target='_blank' onClick={handleOpenScene} href={sceneURL}>Scene</Button>
          </Stack>
        </>
      }
    },
  ];



  return (
    <DataGrid
      sx={{ flex: '1 0 100%' }}
      rows={events}
      columns={columns}
      onRowSelectionModelChange={handleSelection}
    />
  );
};

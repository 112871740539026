import { PageHeader } from '@/components/PageHeader/PageHeader';
import { SportSettings } from '@/components/SportSettings/SportSettings';
import { TabPanel } from '@/components/TabPanel/TabPanel';
import { Store, usePersistStore, useStore } from '@/store/Store';
import { STRINGS } from '@/strings';
import {
  Box,
  FormControlLabel,
  Paper,
  Switch,
  Tab,
  Tabs,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

const selector = (store: Store) => ({
  name: store.name,
  email: store.email,
  token: store.token,
  featchSports: store.featchSports,
  sports: store.sports,
});

export const SettingsView = () => {
  const { isDarkTheme, setDarkTheme } = usePersistStore(useShallow((state) => ({
    isDarkTheme: state.isDarkTheme,
    setDarkTheme: state.setDarkTheme,
  })));
  const { name, email, token, featchSports, sports } = useStore(useShallow(selector));

  useEffect(() => {
    if (!sports) {
      featchSports();
    }
  }, [featchSports, sports]);
  const [currentSport, setCurrentSport] = useState(0);

  const isLoading = !sports;


  const handleSportChange = (_event: React.SyntheticEvent, newValue: number) => {
    setCurrentSport(newValue);
  };

  if (isLoading) return <>
    <PageHeader title='Settings' isLoading={isLoading} />
  </>;

  const tabs = sports.map((sport) => {
    return <Tab key={sport.id} label={sport.name} />;
  });

  const tabPanels = sports.map((sport, i) => {
    return <TabPanel key={sport.id} value={currentSport} index={i}>
      <SportSettings sport={sport} />
    </TabPanel>;
  });

  return (
    <>
      <PageHeader title='Settings' isLoading={isLoading} />

      <Box sx={{ mb: 1 }}>
        <Paper elevation={1} sx={{ padding: 2, mb: 1 }}>
          <Typography variant='h4' gutterBottom>Per Sport Settings</Typography>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentSport} onChange={handleSportChange} aria-label="basic tabs example">
              {tabs}
            </Tabs>
          </Box>
          {tabPanels}
        </Paper>

        <Paper elevation={1} sx={{ padding: 2, mb: 1 }}>
          <Typography variant='h4' gutterBottom>App Settings</Typography>
          <FormControlLabel
            control={
              <Switch
                checked={isDarkTheme}
                onChange={() => setDarkTheme(!isDarkTheme)}
              />
            }
            label={STRINGS.THEME_DARK + ' ' + STRINGS.THEME}
          />
        </Paper>
        <Paper elevation={1} sx={{ padding: 2 }}>
          <Typography variant='h4' gutterBottom>User</Typography>
          <Typography variant='body1'>{name}</Typography>
          <Typography variant='body2'>{email}</Typography>
          <Box sx={{ overflowWrap: 'anywhere', mt: 2 }}>
            <Typography variant='caption'>{token}</Typography>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

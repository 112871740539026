import { CommonDialog } from '@/components/CommonDialog/CommonDialog';
import { PageHeader } from '@/components/PageHeader/PageHeader';
import {
  PROJECT_SELECTOR_SP,
  ProjectSelector,
} from '@/components/SequenceBuilderView/ProjectSelector/ProjectSelector';
import {
  SCREEN_SELECTOR_SP,
  ScreenSelector,
} from '@/components/SequenceBuilderView/ScreenSelector/ScreenSelector';
import { SelectableList } from '@/components/SequenceBuilderView/SelectableList/SelectableList';
import { TemplateDialog } from '@/components/SequenceBuilderView/TemplateDialog/TemplateDialog';
import { TemplatesList } from '@/components/SequenceBuilderView/TemplatesList/TemplatesList';
import { Store, useStore } from '@/store/Store';
import { STRINGS } from '@/strings';
import AddIcon from '@mui/icons-material/Add';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, Paper, Stack } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

export enum SequenceTemplateActions {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
}
const ACTION_PARAM = 'SequenceTemplateAction';

const selector = (store: Store) => ({
  featchProjects: store.featchProjects,
  featchScreens: store.featchScreens,
  templates: store.templates,
  screens: store.screens,
});

export const SequenceTemplateView = () => {
  const { featchProjects, screens, templates } = useStore(useShallow(selector));
  const { templateId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const projectId = searchParams.get(PROJECT_SELECTOR_SP);
  const screenId = searchParams.get(SCREEN_SELECTOR_SP);
  const action = searchParams.get(
    ACTION_PARAM,
  ) as SequenceTemplateActions | null;

  useEffect(() => {
    featchProjects();
  }, [featchProjects]);

  const setAction = (action: SequenceTemplateActions) => {
    setSearchParams((params) => {
      params.set(ACTION_PARAM, action);
      return params;
    });
  };

  const selectedTemplate = useMemo(() => {
    return templates?.find((t) => t.id === templateId);
  }, [templateId, templates]);

  const selectedScreen = useMemo(
    () =>
      projectId
        ? screens?.get(projectId)?.find((s) => s._id === screenId)
        : undefined,
    [screenId, screens, projectId],
  );

  let content = null;
  if (!templateId) {
    content = (
      <>
        <Stack
          alignItems='center'
          justifyContent='center'
          width='100%'
          height='100%'
        >
          <Button
            variant='outlined'
            color='info'
            size='large'
            startIcon={<AddIcon />}
            onClick={() => setAction(SequenceTemplateActions.CREATE)}
          >
            Add New Sequence
          </Button>
        </Stack>
      </>
    );
  } else if (selectedScreen) {
    content = (
      <>
        <Paper elevation={1} sx={{ padding: 2, mt: 1, flex: '1 1' }}>
          <Stack direction='row' gap={1}>
            <Box width='30%'>
              <SelectableList
                title='Designs'
                items={selectedScreen.designs.map((d) => ({
                  ...d,
                  id: d._id,
                }))}
              />
            </Box>
            <Box>TODO</Box>
          </Stack>
        </Paper>
      </>
    );
  }

  return (
    <>
      <Stack direction='row' gap={2} height='100%'>
        <Box
          sx={{
            width: '25%',
            height: '100vh',
            mt: -2,
            ml: -2,
            pr: 1,
            pt: 4,
            pl: 0,
            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <TemplatesList
            onCreate={() => setAction(SequenceTemplateActions.CREATE)}
            onEdit={() => setAction(SequenceTemplateActions.EDIT)}
          />
        </Box>
        <Stack sx={{ height: '100%', flex: 1 }} direction='column'>
          <PageHeader title={selectedTemplate?.name ?? ''} delay={0} />
          <Paper elevation={1} sx={{ padding: 1 }}>
            <Stack direction='row' alignItems='center' gap={1} flexWrap='wrap'>
              <ProjectSelector />
              <ScreenSelector />
              <Box sx={{ flex: 1 }} />
              <Button
                variant='outlined'
                color='primary'
                size='large'
                endIcon={<CreditCardIcon />}
                onClick={() => setAction(SequenceTemplateActions.EDIT)}
              >
                Edit Details
              </Button>
              <Button
                variant='outlined'
                color='error'
                size='large'
                endIcon={<DeleteIcon />}
                onClick={() => setAction(SequenceTemplateActions.DELETE)}
              >
                {STRINGS.DELETE}
              </Button>
              <Button
                variant='outlined'
                color='info'
                size='large'
                endIcon={<SaveIcon />}
              >
                {STRINGS.SAVE}
              </Button>
            </Stack>
          </Paper>
          <Stack sx={{ flex: 1 }}>{content}</Stack>
        </Stack>
      </Stack>

      <TemplateDialog
        open={
          action === SequenceTemplateActions.CREATE ||
          action === SequenceTemplateActions.EDIT
        }
        handleClose={() => setSearchParams({})}
        template={
          action === SequenceTemplateActions.EDIT ? selectedTemplate : undefined
        }
      />

      <CommonDialog
        open={action === SequenceTemplateActions.DELETE}
        title='Delete Template'
        message={
          <>
            <strong>{selectedTemplate?.name}</strong> will be deleted.
            <br />
            Are you sure?
          </>
        }
        cancelText={STRINGS.DIALOG_NO}
        handleClose={() => setSearchParams({})}
      >
        <Button
          variant='contained'
          color='error'
          onClick={() => setSearchParams({})}
        >
          {STRINGS.DIALOG_YES}
        </Button>
      </CommonDialog>
    </>
  );
};

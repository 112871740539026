import { Box, LinearProgress, Typography } from '@mui/material';

export const ConnectingBox = () => {
  return (
    <Box>
      <Box>
        <Typography variant='caption'>Websocket</Typography>
        <Typography variant='h3' sx={{ mb: (theme) => theme.spacing(1) }}>
          Connecting to server...
        </Typography>
        <LinearProgress />
        <Typography mt={1} variant='h6'>
          {import.meta.env.VITE_API_WS_URL}
        </Typography>
      </Box>
    </Box>
  );
};

import { PreviewScene } from "@/components/PreviewScene/PreviewScene";
import { useStore } from "@/store/Store";
import { MccFixtureInfo } from "@/types/mcc";
import { apiURL, fetchData } from "@/utils/fetchData";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useShallow } from 'zustand/react/shallow';

export const SceneSoccerView: React.FC = () => {

    const { eventId, streamId } = useParams();
    const token = useStore(useShallow((state) => state.token));
    const [mccData, setMccData] = useState<MccFixtureInfo | undefined>(undefined);
    useEffect(() => {
        const load = async () =>
            await fetchData<MccFixtureInfo>({
                token,
                url: apiURL(`/soccer/play/${eventId}`),
            });
        load().then((data) => setMccData(data));
    }, [eventId, token]);

    if (!mccData) return <>Loading...</>;

    return <div style={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden' }}>
        <PreviewScene streamId={streamId} mccData={mccData} />
    </div>;
};
import { SportSettingsSlice } from "@/store/SportSettingsSlice";
import { useSportSettingsStore, useStore } from "@/store/Store";
import { MCSoccerEvent } from "@/types/soccer";
import { Sport } from "@/types/sport";
import FolderIcon from '@mui/icons-material/Folder';
import { Accordion, AccordionDetails, AccordionSummary, Button, CircularProgress, FormControl, FormHelperText, InputAdornment, OutlinedInput, Paper, Stack, Typography } from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useShallow } from 'zustand/react/shallow';
import { MCCDesignSelector } from "../MCCombo/MCCDesignSelector";
import { MCDesignVariables } from "../MCDesignVariables/MCDesignVariables";

type Props = {
    sport: Sport;
    soccerEvent?: never;
} | {
    sport?: never;
    soccerEvent: MCSoccerEvent;
}

const selector = (state: SportSettingsSlice) => ({
    isChanged: state.isChanged,
    isOffsetChanged: state.offsetChanged,
    offset: state.offset,
    ready: state.ready,
    states: state.states,
    initFor: state.initFor,
    changeOffset: state.changeOffset,
    changeState: state.changeState,
    save: state.save,
    saveOffset: state.saveOffset,
    featchSettings: state.featchSettings,
    featchOffset: state.featchOffset,
});

export const SportSettings: React.FC<Props> = ({ sport, soccerEvent }) => {

    const { ready,
        isChanged,
        isOffsetChanged,
        offset,
        states,
        initFor,
        changeOffset,
        changeState,
        save,
        saveOffset,
        featchSettings,
        featchOffset,
    } = useSportSettingsStore(useShallow(selector));
    const token = useStore(useShallow((state) => state.token));

    useEffect(() => {
        if (sport) {
            initFor(sport.id, true);
        } else {
            initFor(soccerEvent.id, false);
        }
    }, [sport, soccerEvent, initFor])

    useEffect(() => {
        featchSettings(token);
    }, [featchSettings, token]);

    useEffect(() => {
        featchOffset(token);
    }, [featchOffset, token]);

    if (!ready) {
        return <Stack direction="row" alignItems="center" gap={1}>
            <CircularProgress size={20} /><Typography variant="h6">Loading settings...</Typography>
        </Stack>
    }

    const onSave = () => {
        console.log({
            offset,
            states
        });
        save(token || '');
    }

    const onSaveOffset = () => {
        saveOffset(token);
    }

    const statuses = states.map((state, i) => {
        return <Accordion key={state.id} defaultExpanded={i == 0} elevation={2}>
            <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
            >
                <Stack direction="row" alignItems="center" gap={1}>
                    <FolderIcon color="info" />
                    <Typography variant="h6">{state.name}</Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack gap={3}>
                    <MCCDesignSelector projectId={state.project} screenId={state.screenId} designId={state.designId}
                        changeProject={(id) => changeState(state.id, 'project', id)}
                        changeScreen={(id) => changeState(state.id, 'screenId', id)}
                        changeDesign={(id, _design, variables) => {
                            changeState(state.id, 'designId', id);
                            changeState(state.id, 'variables', variables);
                        }} />
                    {Object.keys(state.variables || {}).length > 0 && <Paper elevation={4} sx={{ p: 1 }}>
                        <MCDesignVariables projectId={state.project} screenId={state.screenId} designId={state.designId} values={state.variables} onChange={variable => {
                            changeState(state.id, 'variables', { ...state.variables, [variable.id]: variable.value });

                        }} />
                    </Paper>}
                </Stack>
            </AccordionDetails>
        </Accordion>
    });

    return <>
        <Stack direction="row" alignItems="end" gap={2} sx={{ mt: 0, mb: 2 }}>
            <FormControl sx={{ width: '25ch' }} variant="outlined">
                <FormHelperText>Time offset</FormHelperText>
                <OutlinedInput size="small" type="number" endAdornment={<InputAdornment position="end">sec</InputAdornment>} value={offset} onChange={(ev) => {
                    changeOffset(Number(ev.target.value));
                }} />
            </FormControl>
            <Button variant="contained" disabled={!isOffsetChanged} onClick={onSaveOffset}>Update offset</Button>
        </Stack>
        {statuses}
        <Stack sx={{ mt: 2 }} justifyContent="right" direction="row" spacing={2}>
            <Button variant="contained" disabled={!isChanged} onClick={onSave}>Save Settings</Button>
        </Stack>
    </>
}
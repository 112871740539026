import { useStore } from '@/store/Store';
import { DDETournament } from '@/types/dde';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  tournaments: DDETournament[];
};

export const TournamentsTableView: React.FC<Props> = ({ tournaments }) => {
  const navigate = useNavigate();
  const store = useStore();

  const handleSelection = (model: GridRowSelectionModel) => {
    store.events = undefined;
    const tournament = tournaments.find(t => t.identifier === model[0]);
    if (tournament?.competitions.length === 1) {
      enqueueSnackbar('Only one competition in this tournament', { variant: 'info' });
      navigate(`${model[0]}/competitions/${tournament.competitions[0].competitionId}/events`);
      return;
    }
    navigate(`${model[0]}/competitions`);
  };

  const columns: GridColDef[] = [
    { field: 'tournamentName', headerName: 'Name', flex: 1 },
    { field: 'sport', headerName: 'Sport', flex: 0.3 },
    { field: 'city', headerName: 'City', flex: 0.55 },
    { field: 'startDate', headerName: 'Start Date', flex: 0.3 },
    { field: 'endDate', headerName: 'End Date', flex: 0.3 },
  ];

  const rows = tournaments.map((tour) => ({
    id: tour.identifier,
    tournamentName: tour.tournamentName,
    sport: tour.sport,
    city: tour.city,
    startDate: tour.startDate,
    endDate: tour.endDate,
  }));

  return (
    <DataGrid
      sx={{ flex: '1 0 100%' }}
      rows={rows}
      columns={columns}
      onRowSelectionModelChange={handleSelection}
    />
  );
};

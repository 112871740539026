import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { FC } from 'react';

export type LoadingButtonProps = ButtonProps & {
  isLoading: boolean;
};
export const LoadingButton: FC<LoadingButtonProps> = ({
  isLoading,
  ...props
}) => {
  return (
    <Button
      {...props}
      disabled={isLoading ? true : props.disabled}
      startIcon={
        isLoading ? (
          <CircularProgress color='inherit' size={'1em'} />
        ) : (
          props.startIcon
        )
      }
    />
  );
};

import { MccFixtureInfo } from '@/types/mcc';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useMemo, useRef } from 'react';
import { VideoStream } from '../VideoStream/VideoStream';

type Props = {
  mccData: MccFixtureInfo;
  streamId?: string;
};

export const PreviewScene: React.FC<Props> = ({ mccData, streamId }) => {
  const frame = useRef<HTMLIFrameElement>(null);
  const circularProgress = useRef<HTMLDivElement>(null);
  const mccURL = `${import.meta.env.VITE_MCC_URL}/${mccData.url}`;

  const videoNode = useMemo(() => {
    if (streamId) {
      return <VideoStream streamId={streamId} />
    } else {
      //return <VideoStream streamId='' hlsTestUrl="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" />
      return <Stack sx={{
        width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center'
      }}>
        <Typography variant="h6" color="textSecondary">Stream not available</Typography>
      </Stack>
    }
  }, [streamId])

  const previewNode = useMemo(() => {

    const PreviewFrame = styled('iframe')({
      position: 'absolute',
      top: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      aspectRatio: '16/9',
      height: '100%',
      border: 'none',
    });

    return <PreviewFrame ref={frame} src={mccURL} onLoad={() => {
      if (circularProgress.current) {
        circularProgress.current.style.display = 'none';
      }
    }} />
  }, [mccURL]);


  return (
    <Box
      sx={{
        border: '1px solid',
        borderColor: (theme) => theme.palette.grey[800],
        borderRadius: '6px',
        aspectRatio: '16/9',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <CircularProgress ref={circularProgress} sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
      <Box style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'transparent' }}>
        {videoNode}
        {previewNode}
      </Box>

    </Box>
  );
};

import { showSnackMessage } from '@/components/SnackMessage/SnackMessage';
import { MCSoccerEvent, SoccerEvent } from '@/types/soccer';
import { apiURL, fetchData } from '@/utils/fetchData';
import { StateCreator } from 'zustand';
import { UserSlice } from './UserSlice';
import { soccerEventToMcEvent } from '@/utils/SoccerUtils';

export type SoccerEventsSlice = {
    soccerEvents?: MCSoccerEvent[];
} & Actions;

type Actions = {
    featchingSoccerEvents: boolean;
    featchSoccerEvents: () => Promise<void>;
}

export const createSoccerEventsSlice: StateCreator<
    SoccerEventsSlice & UserSlice,
    [['zustand/devtools', never]],
    [],
    SoccerEventsSlice
> = (set, get) => ({
    featchingSoccerEvents: false,
    featchSoccerEvents: async () => {
        if (get().featchingSoccerEvents) return;
        set({ featchingSoccerEvents: true });
        await fetchData<SoccerEvent[]>({
            token: get().token,
            url: apiURL(`/soccer/events`),
        })
            .then((events) => {
                set(
                    { soccerEvents: events.map(soccerEventToMcEvent), featchingSoccerEvents: false },
                    false,
                    'SEV soccer events updated',
                );
            })
            .catch((err) => {
                set({ featchingSoccerEvents: false });
                showSnackMessage('Soccer events', `Error while featching events list ${err}`);
            });
    },
});

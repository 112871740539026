import { showSnackMessage } from '@/components/SnackMessage/SnackMessage';
import { MccProject, MccScreen } from '@/types/mcc';
import { SequenceTemplate } from '@/types/sequences';
import { apiURL, fetchData } from '@/utils/fetchData';
import { StateCreator } from 'zustand';
import { SocketSlice } from './SocketSlice';
import { UserSlice } from './UserSlice';

export type ProjectsDataSlice = {
  templates?: SequenceTemplate[];
  projects?: MccProject[];
  screens?: Map<string, MccScreen[]>;

  projectsFeatching: boolean;
  screensFeatching: string[];

  featchTemplates: () => void;
  featchProjects: () => void;
  featchScreens: (projectId: string) => void;
};

export const createProjectsDataSlice: StateCreator<
  ProjectsDataSlice & SocketSlice & UserSlice,
  [['zustand/devtools', never]],
  [],
  ProjectsDataSlice
> = (set, get) => ({
  projectsFeatching: false,
  screensFeatching: [],
  featchTemplates: async () => {
    const response = await fetch('/fake_sequence_templates.json');
    const templates = (await response.json()) as SequenceTemplate[];
    set({ templates }, false, 'PDS templates featched');
  },
  featchProjects: async () => {
    if (get().projectsFeatching) return;
    set({ projectsFeatching: true }, false, 'PDS projects featching');
    await fetchData<MccProject[]>({
      token: get().token,
      url: apiURL('/api/mcc/projects'),
    })
      .then((projects) => {
        set({ projects, projectsFeatching: false }, false, 'PDS projects updated');
      })
      .catch((err) => {
        showSnackMessage('Error while featching projects', `${err.message}`);
        set({ projectsFeatching: false }, false, 'PDS projects featching error');
      });
  },
  featchScreens: async (projectId: string) => {
    if (get().screensFeatching.includes(projectId)) return;
    set(
      { screensFeatching: [...get().screensFeatching, projectId] },
      false,
      'PDS screens featching',
    );
    await fetchData<MccScreen[]>({
      token: get().token,
      url: apiURL(`/api/mcc/projects/${projectId}/screens`),
    })
      .then((screens) => {
        set(
          { screens: new Map(get().screens).set(projectId, screens), screensFeatching: get().screensFeatching.filter((id) => id !== projectId) },
          false,
          'PDS screens updated',
        );
      })
      .catch((err) => {
        set(
          { screensFeatching: get().screensFeatching.filter((id) => id !== projectId) },
          false,
          'PDS screens featching error',
        );
        showSnackMessage('Error while featching screens', `${err.message}`);
      });
  },
});

import { HeroLayout } from '@/components/Layouts/HeroLayout';
import { Layout } from '@/components/Layouts/Layout';
import { Protected } from '@/components/Protected/Protected';
import { AuthProvider } from '@/contexts/AuthProvider';
import { SocketProvider } from '@/contexts/SocketProvider';
import { LiveEvents } from '@/views/LiveEvents/LiveEvents';
import { Page404 } from '@/views/Other/Page404';
import { SceneSoccerView } from '@/views/Scenes/SceneSoccerView';
import { SceneView } from '@/views/Scenes/SceneView';
import { SequenceTemplateView } from '@/views/SequenceTemplate/SequenceTemplateView';
import { SettingsView } from '@/views/Settings/SettingsView';
import { SignInForm } from '@/views/SignInForm';
import { SoccerEvents } from '@/views/SoccerEvents/SoccerEvents';
import { TestFixtureView } from '@/views/Test/TestView';
import { TournamentsView } from '@/views/Tournaments/TournamentsView';
import { LoginCallback } from '@okta/okta-react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { ROUTES } from './constants';

export const router = createBrowserRouter([
  {
    element: (
      <AuthProvider>
        <Protected>
          <SocketProvider>
            <Layout />
          </SocketProvider>
        </Protected>
      </AuthProvider>
    ),
    children: [
      {
        path: ROUTES.HOME,
        element: <Navigate to={ROUTES.TOURNAMENTS} />,
      },
      {
        path: `${ROUTES.TOURNAMENTS}/:tournamentId?/competitions?/:competitionId?/events?/:eventId?`,
        element: <TournamentsView />,
      },
      {
        path: `${ROUTES.LIVE_EVENTS}`,
        element: <LiveEvents />,
      },
      {
        path: `${ROUTES.SOCCER_EVENTS}/:eventId?`,
        element: <SoccerEvents />,
      },
      {
        path: `${ROUTES.SEQUENCE_TEMPLATE}/:templateId?`,
        element: <SequenceTemplateView />,
      },
      {
        path: `${ROUTES.TEST_VIEW}/:fixtureId?`,
        element: <TestFixtureView />,
      },
      {
        path: ROUTES.SETTINGS,
        element: <SettingsView />,
      },
    ],
  },
  {
    element: (
      <AuthProvider>
        <Protected>
          <SceneView />
        </Protected>
      </AuthProvider>
    ),
    path: `${ROUTES.SCENES}/:eventId`,
  },
  {
    element: (
      <AuthProvider>
        <Protected>
          <SceneSoccerView />
        </Protected>
      </AuthProvider>
    ),
    path: `${ROUTES.STREAM_SCENES}/:eventId/:streamId`,
  },
  {
    element: (
      <AuthProvider>
        <HeroLayout />
      </AuthProvider>
    ),
    children: [
      {
        path: ROUTES.SIGN_IN,
        element: <SignInForm />,
      },
      {
        path: '*',
        element: <Page404 />,
      },
    ],
  },
  {
    path: ROUTES.OKTA_LOGIN_CALLBACK,
    element: (
      <AuthProvider>
        <LoginCallback />
      </AuthProvider>
    ),
  },
]);

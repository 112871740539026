import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Grid, Paper, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

export const HeroLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const showBgAnim = keyframes`
  0% { filter: brightness(0.0); transform: scale(1.2) }
  100% { filter: brightness(0.7); transform: scale(1.0) } }`;

  const Bg = styled('div')({
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundImage: 'url(/bg.png)',
    backgroundPosition: 'center',
    animationName: showBgAnim,
    animationDuration: '1s',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
  });

  const showLogoAnim = keyframes`
  0% { filter: brightness(0.0); transform: translate(-50%, -50%) scale(2.0); }
  100% { filter: brightness(1.0); transform: translate(-50%, -50%) scale(1.0); } }`;

  const Logo = styled('img')({
    width: '40%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    animationName: showLogoAnim,
    animationDuration: '1s',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
  });

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        margin: 0,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Grid container sx={{ height: '100%' }} alignItems={'stretch'}>
        <Grid
          item
          xs={6}
          sx={{
            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' },
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <Bg />
          <Logo src='/img_white.png' />
        </Grid>
        <Grid item flex={1}>
          <Stack
            width='100%'
            height='100%'
            alignItems='center'
            justifyContent='center'
          >
            <Paper
              elevation={2}
              sx={{ padding: 5, textAlign: 'left', maxWidth: '90%' }}
            >
              {children ? children : <Outlet />}
            </Paper>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

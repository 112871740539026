import { STRINGS } from '@/strings';
import { Box, Button, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router';

export const Page404 = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <Box>
      <Box sx={{ mb: (theme) => theme.spacing(6) }}>
        <Typography variant='caption'>
          {STRINGS.PAGE_NOT_FOUND_CAPTION}
        </Typography>
        <Typography
          variant='h3'
          color='error'
          sx={{ mb: (theme) => theme.spacing(1) }}
        >
          {STRINGS.PAGE_NOT_FOUND_HEADER}
        </Typography>
        <Typography variant='h6'>{pathname}</Typography>
      </Box>
      <Button variant='outlined' color='error' onClick={() => navigate('/')}>
        Go bach to home
      </Button>
    </Box>
  );
};

import { Store, useStore } from "@/store/Store";
import { useEffect, useMemo } from "react";
import { useShallow } from 'zustand/react/shallow';
import { MCCombo } from "./MCCombo";

type Props = {
    value?: string;
    onChange: (id: string) => void;
}

const selector = (state: Store) => ({
    featchProjects: state.featchProjects,
    projects: state.projects,
});

export const MCComboProjects: React.FC<Props> = ({ value, onChange }) => {

    const { featchProjects, projects } = useStore(useShallow(selector));

    useEffect(() => {
        featchProjects();
    }, [featchProjects]);

    useEffect(() => {
        if (projects && !value) {
            onChange(projects[0]._id);
        }
    }, [projects, onChange, value]);

    const options = useMemo(() => {
        if (!projects) return undefined;
        return projects.reduce((acc, cur) => ({ ...acc, [cur.name]: cur._id }), {});
    }, [projects])

    return <>
        <MCCombo label="Project" options={options} value={value} onChange={onChange} />
    </>
}
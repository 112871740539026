import { PlayerVsFlags } from '@/components/PlayerVsFlags/PlayerVsFlags';
import { Typewriter } from '@/components/Typewriter/Typewriter';
import { useStore } from '@/store/Store';
import { DDETennisEvent, DDETennisEventStatus } from '@/types/dde';
import { MccFixtureInfo } from '@/types/mcc';
import { getFlagUrl, tennisEventName } from '@/utils/Utils';
import { apiURL, fetchData } from '@/utils/fetchData';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useShallow } from 'zustand/react/shallow';
import { SceneButton } from '../../SceneButton/SceneButton';
import { TennisEventContent } from './TennisEventContent';

type Props = {
  event: DDETennisEvent;
};

export const TennisEvent: React.FC<Props> = ({ event }) => {
  const theme = useTheme();
  const token = useStore(useShallow((state) => state.token));
  const { tournamentId, competitionId } = useParams();
  const { projects, featchProjects } = useStore(
    useShallow((state) => ({
      projects: state.projects,
      featchProjects: state.featchProjects,
    })),
  );

  const [mccData, setMccData] = useState<MccFixtureInfo | undefined>(undefined);


  useEffect(() => {
    featchProjects();
  }, [featchProjects]);

  useEffect(() => {
    const load = async () =>
      await fetchData<MccFixtureInfo>({
        token,
        url: apiURL(
          `/stream/tennis/tournaments/${tournamentId}/competitions/${competitionId}/play/${event.eventId}`,
        ),
      }).catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      });
    load().then((data) => setMccData(data || undefined));
  }, [event, token, tournamentId, competitionId]);

  const { statusColor, statusLabel } = [{
    statusLabel: 'IP',
    statusColor: theme.palette.success.main,
    status: DDETennisEventStatus.IN_PROGRESS
  }, {
    statusLabel: 'NS',
    statusColor: theme.palette.error.main,
    status: DDETennisEventStatus.NOT_STARTED
  }, {
    statusLabel: 'S',
    statusColor: theme.palette.warning.main,
    status: DDETennisEventStatus.SUSPENDED
  }, {
    statusLabel: 'F',
    statusColor: theme.palette.grey[600],
    status: DDETennisEventStatus.FINISHED
  }].find((status) => status.status === event.status) || {
    statusLabel: 'Unknown',
    statusColor: theme.palette.grey[600]
  };

  return (
    <Stack sx={{ height: '100%', overflow: 'hidden' }}>
      <PlayerVsFlags tennis={event} />
      <Divider />
      <Stack sx={{ flexDirection: { sm: 'column', md: 'row' }, position: 'relative' }} alignItems='center' gap={1} p={1} zIndex={10}>
        <Stack zIndex={10}
          justifyContent='center'
          sx={{
            display: { xs: 'none', md: 'flex' },
            width: (theme) => theme.spacing(10),
            textAlign: 'center',
            backgroundColor: statusColor,
            p: 1,
            borderRadius: '6px',
          }}
        >

          <Typography
            variant='h4'
            fontWeight={800}
          >{`${statusLabel}`}</Typography>
        </Stack>
        <Divider orientation='vertical' sx={{
          display: { xs: 'none', md: 'flex' },
          zIndex: 10,
        }} />

        <Box zIndex={10}>
          <Stack direction='row' gap={1} alignItems='center' mb={-1.5}>
            <img src={getFlagUrl(event.teamA.team.player1.country)} style={{ height: '1.3em' }} />
            <Typewriter
              mt={0.2}
              variant='h4'
              sx={{ width: '100%' }}
              text={tennisEventName(event, 'title')}
            ></Typewriter>
            <img src={getFlagUrl(event.teamB.team.player1.country)} style={{ height: '1.3em' }} />
          </Stack>
          <Typography variant='overline' sx={{ color: (theme) => theme.palette.grey[300] }}>{event.date} / {event.matchType} / {event.courtName}</Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box zIndex={10}>
          <SceneButton mccData={mccData}>Open Scene</SceneButton>
        </Box>
      </Stack>
      <Divider sx={{ mb: 3 }} />
      {mccData && projects && (
        <TennisEventContent
          event={event}
          competitionId={competitionId || ''}
          mccData={mccData}
          tournamentId={parseInt(tournamentId || '')}
        />
      )}
    </Stack>
  );
};

import { Box, LinearProgress, Stack } from '@mui/material';
import { Typewriter } from '../Typewriter/Typewriter';
import { UserElement } from '../UserElement/UserElement';

type Props = {
  title: string;
  isLoading?: boolean;
  delay?: number;
};

export const PageHeader: React.FC<Props> = ({ title, isLoading, delay }) => {
  return (
    <>
      <Box sx={{ mb: 2, position: 'relative' }}>
        <LinearProgress
          color='info'
          sx={{
            width: '10ch',
            position: 'absolute',
            display: isLoading ? 'block' : 'none',
          }}
        />
        <Stack direction='row' alignItems='center'>
          <Typewriter variant='h2' text={title} delay={delay} />
          <Box flex='1' />
          <UserElement />
        </Stack>
      </Box>
    </>
  );
};

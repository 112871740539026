import { DDESoccerEventStatus, MCSoccerEvent } from '@/types/soccer';
import { soccerEventStatusProperties } from '@/utils/SoccerUtils';
import { keyframes } from '@emotion/react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';

type Props = {
    variant?: 'full' | 'compact';
    event: MCSoccerEvent;
}

export const SoccerEventStatus: React.FC<Props> = ({ event, variant }) => {

    const theme = useTheme();
    const { statusColor, statusLabel, status } = soccerEventStatusProperties(event.status, theme);
    const gradientAnim = keyframes`
    0% { background-position: 0% 0%; }
    50% { background-position: 100% 100%; }
    100% { background-position: 0% 0%; }`;

    const backgroundStyle = event.status === DDESoccerEventStatus.Live ?
        {
            background: `linear-gradient(-45deg, ${statusColor}, ${theme.palette.info.main})`,
            backgroundSize: '200% 200%',
            animation: `${gradientAnim} 1s ease infinite`,
        } :
        { backgroundColor: statusColor };

    switch (variant) {
        case 'full':
            return <>
                <Stack sx={{ backgroundColor: statusColor, p: 1, height: '100%', aspectRatio: 1, borderRadius: theme.shape.borderRadius }}
                    alignItems='center' justifyContent='center'>
                    <Typography variant='h3' fontWeight={800}>{statusLabel}</Typography>
                    <Typography sx={{ mt: -1, fontSize: '0.65rem' }} variant='caption'>{status.toUpperCase()}</Typography>
                </Stack>
            </>
        case 'compact':
            return <>
                <Box sx={{
                    ...backgroundStyle,
                    minWidth: '12ch',
                    pl: 1, pr: 1, pt: 0.3, pb: 0.3,
                    borderRadius: theme.shape.borderRadius,
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}>
                    <Typography variant='caption' fontWeight={800} >{status.toUpperCase()}</Typography>
                </Box>
            </>
    }
};

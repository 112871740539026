export type MccProject = {
  _id: string;
  name: string;
};

export enum MccVariableType {
  Number = 'Number',
  String = 'String',
  Boolean = 'Boolean',
  Progress = 'Progress',
  ProjectColor = 'ProjectColor',
  PositionHorizontal = 'PositionHorizontal',
  PositionVertical = 'PositionVertical',
  LayoutEnum = 'LayoutEnum',
  SizeUnit = 'SizeUnit',
  ImageAsset = 'ImageAsset',
  VideoAsset = 'VideoAsset',
  FillMode = 'FillMode',
  ShapeTypes = 'ShapeTypes',
  GradientColor = 'GradientColor',
  ProjectColorName = 'ProjectColorName',
  BorderSides = 'BorderSides',
};

type MccDesignVariable<T = unknown> = {
  id: string;
  name: string;
  type: MccVariableType;
  value: T;
};

export type MccDesign = {
  _id: string;
  name: string;
  variables?: MccDesignVariable[];
};

export type MccScreen = {
  _id: string;
  name: string;
  designs: MccDesign[];
};

export type MccFixtureInfo = {
  url: string;
  port: number;
};

export type ProjectColor = {
  _id: string;
  color: string;
  createdAt: string;
  name: string;
  projectId: string;
  updatedAt: string;
}

export type MccDesignVariableType = string | number | boolean | Record<string, unknown>;
export type MccChangeDesignData = {
  containerId: number;
  project: string;
  designId: string;
  variables?: Record<string, MccDesignVariableType>;
}
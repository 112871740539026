import { useStore } from "@/store/Store";
import { apiURL, fetchData } from "@/utils/fetchData";
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import { Box, IconButton } from "@mui/material";
import Hls, { Events } from "hls.js";
import { enqueueSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";

type Props = {
    streamId: string;
    hlsTestUrl?: string;
}

type LSResponse = {
    eventId: string;
    hlsUrl: string;
    hls: {
        url: string;
    },
    posterUrl: string;
    statusCode: number;
}

export const VideoStream: React.FC<Props> = ({ streamId, hlsTestUrl }) => {
    const video = useRef<HTMLVideoElement>(null);
    const image = useRef<HTMLImageElement>(null);
    const [hls] = useState(new Hls());
    const token = useStore((state) => state.token);

    useEffect(() => {
        image.current?.style.setProperty('visibility', 'hidden');
        if (hlsTestUrl) {
            if (video.current) {
                video.current.src = hlsTestUrl;
            }
            return;
        }

        let isLoading = true;
        let interval: NodeJS.Timeout;
        const load = () => {
            fetchData<LSResponse>({
                url: apiURL(`/live-streaming/event/${streamId}`),
                //url: apiURL(`/live-streaming/event/${2}`),
                token,
            }).catch(() => {
                enqueueSnackbar('Failed to video stream', { variant: 'error' });
            }).then(response => {
                if (isLoading) {
                    const videoElement = video.current;
                    if (videoElement && response) {
                        hls.attachMedia(videoElement);
                        hls.on(Events.MEDIA_ATTACHED, () => {
                            hls.loadSource(
                                response.hlsUrl
                            );
                        });
                    }
                    const imageElement = image.current;
                    if (imageElement) {
                        imageElement.style.visibility = (response?.posterUrl && response.posterUrl.length > 0) ? 'visible' : 'hidden';
                        imageElement.src = response?.posterUrl ? response.posterUrl : '';
                    }
                }
            })

        };
        load();
        return () => {
            isLoading = false;
            clearTimeout(interval);
        }
    }, [streamId, token, hlsTestUrl, hls]);

    const [muted, setMuted] = useState(true);
    const muteUnmuteVideo = () => {
        if (video.current) {
            video.current.muted = !video.current.muted;
            setMuted(video.current.muted);
        }
    }

    return (
        <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
            <img ref={image} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
            <video ref={video} autoPlay muted loop style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
            <IconButton sx={{ position: 'absolute', bottom: 1, right: 1, zIndex: 1000 }} onClick={muteUnmuteVideo}>
                {muted ? <VolumeOffIcon /> : <VolumeMuteIcon />}
            </IconButton>
        </Box>
    );
};
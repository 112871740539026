import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { AppThemeProvider } from './contexts/AppThemeProvider';
import './globals.css';
import { router } from './router/router';

createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <AppThemeProvider>
      <SnackbarProvider />
      <RouterProvider router={router}></RouterProvider>
    </AppThemeProvider>
  </React.StrictMode>,
);

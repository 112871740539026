import { Box, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React from 'react';

type Props = {
  title?: string;
  message?: string;
};

const SnackMessage: React.FC<Props> = ({
  title = 'MCCS Streaming',
  message = 'Unknown event happened',
}) => {
  return (
    <>
      <Box>
        <Typography variant='h6'>{title}</Typography>
        <Typography variant='overline'>{message}</Typography>
      </Box>
    </>
  );
};
type Variant = 'success' | 'error' | 'warning' | 'info' | undefined;
export const showSnackMessage = (
  title: Props['title'],
  message: Props['message'],
  variant: Variant = 'error',
) => {
  enqueueSnackbar({
    variant,
    message: <SnackMessage title={title} message={message} />,
  });
};

import { UserSlice, createUserSlice } from '@/store/UserSlice';
import { devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { createWithEqualityFn } from 'zustand/traditional';
import {
  PersistStorageSlice,
  createPersistStorageSlice,
} from './PersistStorageSlice';
import {
  ProjectsDataSlice,
  createProjectsDataSlice,
} from './ProjectsDataSlice';
import { SoccerEventsSlice, createSoccerEventsSlice } from './SoccerEventsSlice';
import { SocketSlice, createSocketSlice } from './SocketSlice';
import { SportSettingsSlice, createSportSettingsSlice } from './SportSettingsSlice';
import { SportsSlice, createSportsSlice } from './SportsSlice';
import { TestFixtureSlice, createTestPageSlice } from './TestPageSlice';
import {
  TournamentsDataSlice,
  createTournamentsDataSlice,
} from './TournamentsDataSlice';

export type Store = UserSlice &
  SocketSlice &
  ProjectsDataSlice &
  TournamentsDataSlice &
  SportsSlice &
  SoccerEventsSlice;

export const useStore = createWithEqualityFn<Store>()(
  immer(
    devtools(
      (...a) => ({
        ...createUserSlice(...a),
        ...createSocketSlice(...a),
        ...createProjectsDataSlice(...a),
        ...createTournamentsDataSlice(...a),
        ...createSportsSlice(...a),
        ...createSoccerEventsSlice(...a),
      }),
      {
        enabled: true,
        name: 'MCCS Store',
      },
    )
  ),
);

export const useSportSettingsStore = createWithEqualityFn<SportSettingsSlice>()(
  devtools(
    (...a) => ({
      ...createSportSettingsSlice(...a),
    }),
    {
      enabled: true,
      name: 'MCCS Sport Settings Store',
    },
  ),
);

export type StorePersist = PersistStorageSlice & TestFixtureSlice;
export const usePersistStore = createWithEqualityFn<StorePersist>()(
  devtools(
    persist(
      (...a) => ({
        ...createPersistStorageSlice(...a),
        ...createTestPageSlice(...a),
      }),
      {
        name: 'MCCS Persist Store',
      },
    ),
    {
      enabled: true,
      name: 'MCCS Persist Store',
    },
  ),
);

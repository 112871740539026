import { ROUTES } from '@/router/constants';
import { Store, useStore } from '@/store/Store';
import AddIcon from '@mui/icons-material/Add';
import FolderIcon from '@mui/icons-material/Folder';
import {
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const selector = (store: Store) => ({
  templates: store.templates,
  featchTemplates: store.featchTemplates,
});

type Props = {
  onCreate: () => void;
  onEdit: () => void;
};

export const TemplatesList: React.FC<Props> = ({ onCreate: onAdd }) => {
  const { templates, featchTemplates } = useStore(selector);

  useEffect(() => {
    featchTemplates();
  }, [featchTemplates]);

  const { templateId = '' } = useParams();

  const navigate = useNavigate();

  const handleTemplateClick = (templateId: string) => {
    navigate(`${ROUTES.SEQUENCE_TEMPLATE}/${templateId}`);
  };

  return (
    <>
      <Stack direction='row' justifyContent='end' mt={1} mb={2}>
        <Button variant='outlined' color='info' onClick={onAdd}>
          <AddIcon />
        </Button>
      </Stack>
      <Divider />

      <List dense={true} sx={{ mt: 1 }}>
        {templates?.map((template) => (
          <ListItemButton
            key={template.id}
            selected={template.id == templateId}
            onClick={() => handleTemplateClick(template.id)}
          >
            <ListItemIcon>
              <FolderIcon />
            </ListItemIcon>
            <ListItemText primary={template.name} />
          </ListItemButton>
        ))}
      </List>
    </>
  );
};

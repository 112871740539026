import {
  FormControl,
  FormControlOwnProps,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Control, Controller } from 'react-hook-form';

type OptionType = {
  value: string;
  label: string;
};

type Props = {
  label: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  options: OptionType[];
  formProps?: FormControlOwnProps;
};

export const FormInputDropdown: React.FC<Props> = ({
  name,
  control,
  label,
  options,
  formProps,
}) => {
  const generateSingleOptions = () => {
    return options.map((option) => {
      return (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      );
    });
  };
  return (
    <FormControl {...formProps}>
      <InputLabel>{label}</InputLabel>
      <Controller
        render={({ field: { onChange, value } }) => (
          <Select onChange={onChange} value={value}>
            {generateSingleOptions()}
          </Select>
        )}
        control={control}
        name={name}
      />
    </FormControl>
  );
};

import { Store, useStore } from '@/store/Store';
import { MccScreen } from '@/types/mcc';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { PROJECT_SELECTOR_SP } from '../ProjectSelector/ProjectSelector';

const selector = (store: Store) => ({
  screens: store.screens,
  featchScreens: store.featchScreens,
});

export const SCREEN_SELECTOR_SP = 'selectedScreen';

type Props = {
  currentProjectId?: MccScreen['_id'];
  currentScreenId?: string;
  setCurrentScreenId?: (screenId: MccScreen['_id']) => void;
};

export const ScreenSelector: React.FC<Props> = ({ currentProjectId, setCurrentScreenId, currentScreenId }) => {
  const { screens, featchScreens } = useStore(useShallow(selector));
  const [serachParams, setSearchParams] = useSearchParams();

  const screenId = currentScreenId || serachParams.get(SCREEN_SELECTOR_SP);
  const projectId = currentProjectId || serachParams.get(PROJECT_SELECTOR_SP);
  useEffect(() => {
    if (projectId && !screens?.has(projectId)) {
      featchScreens(projectId);
    }
  }, [projectId, screens, featchScreens]);

  const projectScreens = useMemo(() => {
    if (projectId && screens?.has(projectId)) {
      return screens.get(projectId);
    }
    return undefined;
  }, [projectId, screens]);

  const handleChange = (event: SelectChangeEvent) => {
    if (setCurrentScreenId) {
      setCurrentScreenId(event.target.value as MccScreen['_id']);
    } else {
      setSearchParams((prev) => {
        prev.set(SCREEN_SELECTOR_SP, event.target.value);
        return prev;
      });
    }
  };

  return (
    <>
      <FormControl
        sx={{ m: 1, minWidth: '20%' }}
        size='small'
        disabled={screens === undefined}
      >
        <InputLabel>Screen</InputLabel>
        <Select
          autoWidth
          value={screenId && projectScreens?.find(ps => ps._id === screenId) ? screenId : ''}
          label='Sceen'
          onChange={handleChange}
        >
          {projectScreens?.map((item) => (
            <MenuItem key={item._id} value={item._id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

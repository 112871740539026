import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';

export interface SelectableListItem {
  id: string;
  name: string;
}

type Props = {
  title: string;
  items: SelectableListItem[];
};

function not<T>(a: readonly T[], b: readonly T[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection<T>(a: readonly T[], b: readonly T[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union<T>(a: readonly T[], b: readonly T[]) {
  return [...a, ...not(b, a)];
}

export const SelectableList: React.FC<Props> = ({ title, items }) => {
  const [checked, setChecked] = React.useState<readonly string[]>([]);

  const numberOfChecked = (items: readonly SelectableListItem[]) =>
    intersection(
      checked,
      items.map((i) => i.id),
    ).length;

  const handleToggleAll = (items: readonly SelectableListItem[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(
        not(
          checked,
          items.map((i) => i.id),
        ),
      );
    } else {
      setChecked(
        union(
          checked,
          items.map((i) => i.id),
        ),
      );
    }
  };

  const handleToggle = (value: SelectableListItem) => () => {
    const currentIndex = checked.indexOf(value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value.id);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  return (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component='div'
        role='list'
      >
        {items.map((item: SelectableListItem) => {
          const labelId = `transfer-list-all-item-${item}-label`;
          return (
            <ListItem
              key={item.id}
              role='listitem'
              button
              onClick={handleToggle(item)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(item.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={item.name} />
            </ListItem>
          );
        })}
      </List>
    </Card>
  );
};

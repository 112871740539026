import { useStore } from "@/store/Store";
import { MccDesign, MccScreen } from "@/types/mcc";
import { useEffect, useMemo } from "react";
import { useShallow } from 'zustand/react/shallow';
import { MCCombo } from "./MCCombo";

type Props = {
    projectId?: string;
    screenId?: string;
    value?: string;
    onChange: (id: string, design?: MccDesign) => void;
}

export const MCComboDesigns: React.FC<Props> = ({ value, onChange, projectId, screenId }) => {

    const { screens } = useStore(useShallow(state => ({ screens: state.screens })));

    const screen = useMemo<MccScreen | undefined>(() => {
        if (!screenId || !screens || !projectId) return undefined;
        if (screens.has(projectId)) return screens.get(projectId)!.find((s) => s._id === screenId);
        return undefined;
    }, [screens, screenId, projectId]);

    const options = useMemo(() => {
        if (!screen) return undefined;
        return screen.designs.reduce((acc, cur) => ({ ...acc, [cur.name]: cur._id }), {});
    }, [screen])

    const comboValue = useMemo(() => {
        if (!options || !screen || screen.designs.length === 0) return undefined;
        if (Object.values(options).includes(value)) {
            return value;
        } else {
            const id = screen.designs[0]._id;
            return id;
        }
    }, [options, screen, value]);

    useEffect(() => {
        if (comboValue && comboValue !== value) {
            onChange(comboValue, screen?.designs.find((d) => d._id === comboValue));
        }
    }, [comboValue, value, onChange, screen]);

    return <>
        <MCCombo label="Designs" options={options} value={comboValue} onChange={(id) => {
            onChange(id, screen?.designs.find((d) => d._id === id));
        }} />
    </>
}
import { DDETennisEvent } from '@/types/dde';
import { tennisEventName } from '@/utils/Utils';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  events: DDETennisEvent[];
};

export const TennisEventsTableView: React.FC<Props> = ({ events }) => {
  const navigate = useNavigate();

  const handleSelection = (model: GridRowSelectionModel) => {
    navigate(`${model[0]}`);
  };

  const eventsData = React.useMemo(() => {
    return events.map((event) => {
      return {
        ...event,
        playerVs: tennisEventName(event),
      };
    });
  }, [events]);

  const columns: GridColDef[] = [
    { field: 'type', headerName: 'Type', flex: 0.2 },
    { field: 'playerVs', headerName: 'Match', flex: 1 },
    { field: 'date', headerName: 'Date', flex: 0.55 },
    { field: 'startTimeText', headerName: 'Start Time', flex: 0.3 },
    { field: 'status', headerName: 'Status', flex: 0.3 },
  ];

  const rows = eventsData.map((ev) => ({
    id: ev.eventId,
    type: ev.matchType,
    playerVs: ev.playerVs,
    date: ev.date,
    startTimeText: ev.startTimeText,
    status: ev.status,
  }));

  return (
    <DataGrid
      sx={{ flex: '1 0 100%' }}
      rows={rows}
      columns={columns}
      onRowSelectionModelChange={handleSelection}
    />
  );
};

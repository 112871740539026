import { Store, useStore } from "@/store/Store";
import { useEffect, useMemo } from "react";
import { useShallow } from 'zustand/react/shallow';
import { MCCombo } from "./MCCombo";

type Props = {
    projectId?: string;
    value?: string;
    onChange: (id: string) => void;
}

const selector = (state: Store) => ({
    featchScreens: state.featchScreens,
    projectsFeatching: state.projectsFeatching,
    screens: state.screens,
});

export const MCComboScreens: React.FC<Props> = ({ projectId, value, onChange }) => {

    const { featchScreens, screens } = useStore(useShallow(selector));

    const projectScreens = useMemo(() => {
        if (!projectId) return undefined;
        if (screens?.has(projectId)) return screens.get(projectId);
        featchScreens(projectId);
    }, [screens, featchScreens, projectId]);

    const options = useMemo(() => {
        if (!projectScreens) return undefined;
        return projectScreens.reduce((acc, cur) => ({ ...acc, [cur.name]: cur._id }), {});
    }, [projectScreens]);

    const comboValue = useMemo(() => {
        if (!options || !screens || screens.size === 0) return undefined;
        if (Object.values(options).includes(value)) {
            return value;
        } else if (projectId) {
            const id = screens.get(projectId)![0]._id;
            return id;
        }
    }, [options, screens, projectId, value]);

    useEffect(() => {
        if (comboValue && comboValue !== value) {
            onChange(comboValue);
        }
    }, [comboValue, value, onChange]);

    return <>
        <MCCombo label="Screens" options={options} value={comboValue} onChange={onChange} />
    </>
}
import { StateCreator } from 'zustand';

export type TestFixture = {
  id: string;
  projectId?: string;
  screenId?: string;
  designId?: string;
  variables: Record<string, unknown>;
};

export type TestFixtureSlice = {
  testFixtures: TestFixture[];
  addTestFixture: (fixture: TestFixture) => void;
  modifyTestFixture: (
    fixtureId: TestFixture['id'],
    fixtureChange: Partial<TestFixture>,
  ) => void;
  deleteTestFixture: (fixtureId: TestFixture['id']) => void;
};

export const createTestPageSlice: StateCreator<
  TestFixtureSlice,
  [['zustand/devtools', never]],
  [['zustand/persist', TestFixtureSlice]],
  TestFixtureSlice
> = (set, get) => ({
  testFixtures: [],
  addTestFixture: (fixture) => {
    fixture.variables = { ...fixture.variables };
    set(
      { testFixtures: [...get().testFixtures, fixture] },
      false,
      'TPS Fixture added',
    );
  },
  modifyTestFixture: (fixtureId, fixtureChange) => {
    const fixtures = [...get().testFixtures].map((f) => {
      if (f.id === fixtureId) {
        return { ...f, ...fixtureChange };
      }
      return f;
    });
    set({ testFixtures: fixtures }, false, 'TPS Fixture modified');
  },
  deleteTestFixture: (fixtureId) => {
    const fixtures = [...get().testFixtures].filter((f) => f.id !== fixtureId);
    set({ testFixtures: fixtures }, false, 'TPS Fixture deleted');
  },
});

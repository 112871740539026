import { ROUTES } from '@/router/constants';
import { usePersistStore } from '@/store/Store';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import SettingsIcon from '@mui/icons-material/Settings';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import { Box, Paper, Stack, Tab, Tabs } from '@mui/material';
import { useRef } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export const Layout = () => {
  const navigate = useNavigate();
  const isDarkTheme = usePersistStore((state) => state.isDarkTheme);
  const lastPathName = useRef('');

  const location = useLocation();

  const pathNameValue = `/${location.pathname.split('/')[1]}`;
  const pathName = pathNameValue == '/' ? ROUTES.TOURNAMENTS : pathNameValue;
  const isPageChanged = lastPathName.current !== pathName;
  lastPathName.current = pathName;

  const showContentAnim = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }`;

  const ContentContainer = styled('div')((params) => ({
    width: '100%',
    flex: 1,
    animationName: showContentAnim,
    animationDuration: params.style?.animationDuration || '0.6s',
    animationTimingFunction: 'ease-out',
    animationFillMode: 'forwards',
  }));

  const LogoContainer = styled('div')({
    width: '100%',
    aspectRatio: '1/1',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  });

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    navigate(newValue);
  };

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        margin: 0,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack direction='row' gap={2} sx={{ height: 1 / 1 }}>
        <Paper elevation={0}>
          <Tabs
            orientation='vertical'
            variant='scrollable'
            value={Object.values(ROUTES).indexOf(pathName) != -1 ? pathName : 0}
            onChange={handleChange}
            aria-label='Main navigation'
            sx={{ borderRight: 1, borderColor: 'divider', height: 1 / 1 }}
          >
            <LogoContainer>
              <img
                src={
                  isDarkTheme ? '/img_white_small.png' : '/img_black_small.png'
                }
                alt='logo'
                style={{ width: '3rem' }}
              />
            </LogoContainer>
            <Tab
              icon={<EmojiEventsIcon />}
              value={ROUTES.TOURNAMENTS}
              {...a11yProps(0)}
            />
            <Tab
              icon={<SportsTennisIcon />}
              value={ROUTES.LIVE_EVENTS}
              {...a11yProps(0)}
            />
            <Tab
              icon={<SportsSoccerIcon />}
              value={ROUTES.SOCCER_EVENTS}
              {...a11yProps(0)}
            />
            {/* <Tab
              icon={<AccountTreeIcon />}
              value={ROUTES.SEQUENCE_TEMPLATE}
              {...a11yProps(1)}
            /> */}
            <Tab
              icon={<LogoDevIcon />}
              value={ROUTES.TEST_VIEW}
              {...a11yProps(1)}
            />
            <Tab label='' icon={<Box />} disabled sx={{ flex: 1 }} />
            <Tab
              sx={{ mb: 2 }}
              icon={<SettingsIcon />}
              value={ROUTES.SETTINGS}
              {...a11yProps(5)}
            />
          </Tabs>
        </Paper>
        <Box
          sx={{
            overflowY: 'auto',
            padding: 2,
            flex: 1,
            display: 'flex',
            width: 1 / 1,
          }}
        >
          <ContentContainer
            style={{ animationDuration: isPageChanged ? '0.6s' : '0s' }}
          >
            <Outlet />
          </ContentContainer>
        </Box>
      </Stack>
    </Box>
  );
};

import { Typewriter } from '@/components/Typewriter/Typewriter';
import { STRINGS } from '@/strings';
import { Box, Button, Dialog, Paper, Stack, Typography } from '@mui/material';
import { useCallback } from 'react';

type Props = {
  open: boolean;
  title?: string;
  message: string | JSX.Element;
  cancelText?: string;
  handleClose: () => void;
} & React.PropsWithChildren;

export const CommonDialog: React.FC<Props> = ({
  open,
  handleClose,
  title = STRINGS.DIALOG_TITLE,
  message,
  cancelText = STRINGS.DIALOG_CANCEL,
  children,
}) => {
  const close = useCallback(() => {
    handleClose();
  }, [handleClose]);

  return (
    <Dialog open={open} onClose={close}>
      <Paper elevation={1} sx={{ padding: 3, textAlign: 'left' }}>
        <Box mb={2}>
          <Typewriter
            variant='h5'
            align='center'
            width='100%'
            sx={{ mb: (theme) => theme.spacing(1) }}
            text={title}
          />
          <Typography width='100%' align='center' variant='body1'>
            {message}
          </Typography>
        </Box>
        <Stack
          direction='row'
          sx={{ mx: 'auto' }}
          gap={2}
          justifyContent='center'
        >
          {children}
          <Button variant='contained' color='info' onClick={close}>
            {cancelText}
          </Button>
        </Stack>
      </Paper>
    </Dialog>
  );
};

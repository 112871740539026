import { DDESoccerEventStatus, MCSoccerEvent, SoccerEvent } from "@/types/soccer";
import { Theme } from "@mui/material";

export const soccerEventToMcEvent = (event: SoccerEvent): MCSoccerEvent => {
    const id = event.id;
    const name = (event.ddeEvent?.name || event.streamEvent?.title)!;
    const venue = (event.ddeEvent?.venue.name || event.streamEvent?.tournament.name)!;
    const ddeId = event.ddeEvent?.id;
    const streamId = event.streamEvent?.id.toString();
    const status = event.ddeEvent?.status;
    return {
        id,
        name,
        venue,
        ddeId,
        streamId,
        raw: event,
        status,
    }
};


export const soccerEventStatusProperties = (eventStatus: DDESoccerEventStatus | undefined, theme: Theme) => {
    return [
        {
            statusLabel: 'SH',
            statusColor: theme.palette.info.main,
            status: DDESoccerEventStatus.Scheduled
        },
        {
            statusLabel: 'UV',
            statusColor: theme.palette.warning.main,
            status: DDESoccerEventStatus.Unverified
        },
        {
            statusLabel: 'LV',
            statusColor: theme.palette.success.main,
            status: DDESoccerEventStatus.Live
        },
        {
            statusLabel: 'CP',
            statusColor: theme.palette.grey[500],
            status: DDESoccerEventStatus.Completed,
        },
        {
            statusLabel: 'SP',
            statusColor: theme.palette.warning.main,
            status: DDESoccerEventStatus.Suspended,
        },
        {
            statusLabel: 'PP',
            statusColor: theme.palette.warning.main,
            status: DDESoccerEventStatus.Postponed
        },
        {
            statusLabel: 'AB',
            statusColor: theme.palette.error.main,
            status: DDESoccerEventStatus.Abandoned
        },
        {
            statusLabel: 'CC',
            statusColor: theme.palette.error.main,
            status: DDESoccerEventStatus.Cancelled
        },
    ].find((status) => status.status === eventStatus) || {
        statusLabel: 'UK',
        statusColor: theme.palette.grey[600],
        status: 'Unknown'
    };
}
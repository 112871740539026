import { ROUTES } from '@/router/constants';
import { usePersistStore, useStore } from '@/store/Store';
import { STRINGS } from '@/strings';
import { stringAvatar } from '@/utils/Utils';
import { Avatar } from '@mui/material';
import IconButton from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useOktaAuth } from '@okta/okta-react';
import * as React from 'react';
import { useCallback } from 'react';
import { useShallow } from 'zustand/react/shallow';

export const UserElement = () => {
  const name = useStore(useShallow((state) => state.name));
  const { oktaAuth } = useOktaAuth();

  const signOut = useCallback(() => {
    oktaAuth.signOut({
      postLogoutRedirectUri: `${window.location.origin}/${ROUTES.HOME}`,
    });
  }, [oktaAuth]);

  const { isDarkTheme, setDarkTheme } = usePersistStore((state) => ({
    isDarkTheme: state.isDarkTheme,
    setDarkTheme: state.setDarkTheme,
  }));

  const switchTheme = useCallback(() => {
    setDarkTheme(!isDarkTheme);
  }, [setDarkTheme, isDarkTheme]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!name) return <></>;

  return (
    <>
      <IconButton
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Avatar {...stringAvatar(name)} />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={switchTheme}>
          {isDarkTheme ? STRINGS.THEME_LIGHT : STRINGS.THEME_DARK}{' '}
          {STRINGS.THEME}
        </MenuItem>
        <MenuItem onClick={signOut}>Logout</MenuItem>
      </Menu>
    </>
  );
};

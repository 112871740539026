import { StateCreator } from 'zustand';
import { ProjectsDataSlice } from './ProjectsDataSlice';

export type SocketSlice = {
  webSocket?: WebSocket;
  ready: boolean;
  keepAlive: boolean;
  retryCount: number;
  connect: () => void;
  disconnect: () => void;
  sendWsAction: (action: string, args?: unknown | undefined) => void;
  pingInterval?: NodeJS.Timeout;
  lastPing: number;
  lastPong: number;
  lastUnknownAction?: string;
};

type SocketResponse<T = unknown> = {
  action: string;
  arguments: T;
};

const PING_ACTION = 'ping';

export const createSocketSlice: StateCreator<
  SocketSlice & ProjectsDataSlice,
  [['zustand/devtools', never]],
  [],
  SocketSlice
> = (set, get) => ({
  ready: false,
  keepAlive: false,
  retryCount: 0,
  lastPing: 0,
  lastPong: Number.MAX_SAFE_INTEGER,
  connect: () => {
    if (get().webSocket) return;
    const webSocket = new WebSocket(import.meta.env.VITE_API_WS_URL);
    set({ webSocket, keepAlive: true }, false, 'WS Connecting with keep alive');

    const reconnect = () => {
      if (!get().keepAlive) return;
      const timeout = get().retryCount === 0 ? 0 : 5000;
      setTimeout(() => {
        set({ retryCount: get().retryCount + 1 }, false, 'WS Reconnect');
        get().connect();
      }, timeout);
    };

    const ping = () => {
      setTimeout(
        () => {
          if (!get().ready) return;
          set({ lastPing: Date.now() }, false, 'WS Ping');
          get().sendWsAction(PING_ACTION);
          ping();
        },
        import.meta.env.VITE_API_WS_PING_INTERVAL * 1000,
      );
    };

    webSocket.onopen = () => {
      set({ retryCount: 0, ready: true }, false, 'WS Connected');
      ping();
    };
    webSocket.onclose = () => {
      set({ ready: false, webSocket: undefined }, false, 'WS Closed');
      reconnect();
    };
    webSocket.onerror = (err) => {
      set({ ready: false }, false, `WS Error ${err}`);
      reconnect();
    };
    webSocket.onmessage = (event) => {
      const data = JSON.parse(event.data) as SocketResponse;
      switch (data.action) {
        case 'Pong':
          set({ lastPong: Date.now() }, false, 'WS Pong');
          break;
        default:
          set({ lastUnknownAction: data.action }, false, 'WS Unknown action');
      }
    };
  },
  disconnect: () => {
    const webSocket = get().webSocket;
    if (webSocket) {
      webSocket.onopen = null;
      webSocket.onclose = null;
      webSocket.onerror = null;
      webSocket.onmessage = null;
    }
    set(
      { keepAlive: false, webSocket: undefined },
      false,
      'WS Disconnected by user keep alive off',
    );
    if (webSocket?.readyState == 1) webSocket.close();
  },
  sendWsAction: (action, args) => {
    const webSocket = get().webSocket;
    if (webSocket?.readyState == 1)
      webSocket.send(JSON.stringify({ action, arguments: args }));
  },
});

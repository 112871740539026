import { showSnackMessage } from '@/components/SnackMessage/SnackMessage';
import { MccDesignVariableType } from '@/types/mcc';
import { apiURL, fetchData } from '@/utils/fetchData';
import { StateCreator } from 'zustand';
import { immer } from 'zustand/middleware/immer';

export type SportState = {
    id: string,
    name: string,
    designId: string,
    screenId: string,
    project: string,
    variables?: Record<string, MccDesignVariableType>;
}

export type SportSettings = {
    offset: number;
    states: SportState[];
}

type SaveSettingsResponse = {
    createdAt: string;
    updatedAt: string;
    fixtureId: string;
    offset: number;
    settings: { states: SportSettings['states'] };
}

export type SportSettingsSlice = {
    ready: boolean;
    isChanged: boolean;
    offsetChanged: boolean;
    id?: string;
    isSport?: boolean;
    baseURL?: string;
    initFor(id: string, isSport: boolean): void;
    changeOffset: (offset: number) => void;
    changeState: <T extends keyof SportState, K extends SportState[T]>(state: string, key: T, value: K) => void;
    featchSettings: (token?: string) => void;
    featchOffset: (token?: string) => void;
    save: (token?: string) => void;
    saveOffset: (token?: string) => void;
} & SportSettings;

const defaultState = {
    ready: false,
    isChanged: false,
    offsetChanged: false,
    offset: 0,
    states: [],
}

export const createSportSettingsSlice: StateCreator<
    SportSettingsSlice,
    [['zustand/devtools', never]],
    [['zustand/immer', never]],
    SportSettingsSlice
> = immer((set, get) => ({
    ...defaultState,
    initFor: (id: string, isSport: boolean) => {
        if (get().id === id) return;
        set({
            ...defaultState,
            id,
            isSport,
            baseURL: apiURL(isSport ? `/soccer` : `/soccer/fixtures/${id}`),
        });
    },
    changeOffset: (offset) => {
        set({ offset, offsetChanged: true }, false, 'SSS Offset changed');
    },
    changeState: (state, key, value) => {
        set((store) => {
            const index = store.states.findIndex((s) => s.id === state);
            if (index === -1) return store;
            store.states[index][key] = value;
            store.isChanged = true;
        }, false, `SSS Sport state ${state} ${key} changed`);
    },
    featchSettings: async (token) => {
        if (!get().id) throw new Error('SportSettingsSlice: id is not set');
        set({ ready: false, isChanged: false });
        const url = `${get().baseURL}/settings`;
        await fetchData<Omit<SportSettings, 'offset'>>({
            token: token,
            url,
        })
            .then(({ states }) => {
                set(
                    { states, ready: true },
                    false,
                    'SSS settings featched',
                );
            })
            .catch((err) => {
                set({ ready: false }, false, 'SSS Error while featching settings');
                showSnackMessage('Error while featching settings', `${err.message}`);
            });
    },
    featchOffset: async (token) => {
        if (!get().id) throw new Error('SportSettingsSlice: id is not set');
        set({ ready: false, isChanged: false });
        await fetchData<Omit<SportSettings, 'states'>>({
            token: token,
            url: `${get().baseURL}/offset`,
        })
            .then(({ offset }) => {
                set(
                    { offset, offsetChanged: false },
                    false,
                    'SSS offset featched',
                );
            })
            .catch((err) => {
                set({ ready: false }, false, 'SSS Error while featching settings');
                showSnackMessage('Error while featching settings', `${err.message}`);
            });
    },
    save: async (token) => {
        if (!get().id) throw new Error('SportSettingsSlice: id is not set');
        await fetchData<SaveSettingsResponse>({
            token: token,
            url: `${get().baseURL}/settings`,
            method: 'POST',
            data: { states: get().states },
        })
            .then((response) => {
                set(
                    { isChanged: false, states: response.settings.states },
                    false,
                    'SSS settings saved',
                );
                showSnackMessage('Settings saved', 'Settings saved successfully', 'success');
            })
            .catch((err) => {
                showSnackMessage('Error while saving settings', `${err.message}`);
            });
    },
    saveOffset: async (token) => {
        if (!get().id) throw new Error('SportSettingsSlice: id is not set');
        await fetchData<SaveSettingsResponse>({
            token: token,
            url: `${get().baseURL}/offset`,
            method: 'POST',
            data: { offset: get().offset },
        })
            .then((response) => {
                set(
                    { offsetChanged: false, offset: response.offset },
                    false,
                    'SSS offset saved',
                );
                showSnackMessage('Offset saved', 'Offset saved successfully', 'success');
            })
            .catch((err) => {
                showSnackMessage('Error while saving offset', `${err.message}`);
            });
    }
}));

import { MccDesign, MccDesignVariableType } from '@/types/mcc';
import { Box, Stack } from '@mui/material';
import React from 'react';
import { MCComboDesigns } from './MCComboDesigns';
import { MCComboProjects } from './MCComboProjects';
import { MCComboScreens } from './MCComboScreens';

type Props = {
    projectId?: string;
    screenId?: string;
    designId?: string;
    changeProject: (id: string) => void;
    changeScreen: (id: string) => void;
    changeDesign: (id: string, design?: MccDesign, variables?: Record<string, MccDesignVariableType>) => void;
} & React.PropsWithChildren

export const MCCDesignSelector: React.FC<Props> = ({
    projectId,
    screenId,
    designId,
    changeProject,
    changeScreen,
    changeDesign,
    children,
}) => {

    return <Stack sx={{ width: '100%' }} direction="row" gap={1} alignItems='start' flexWrap='wrap'>
        <MCComboProjects value={projectId} onChange={changeProject} />
        <MCComboScreens projectId={projectId} value={screenId} onChange={changeScreen} />
        <MCComboDesigns projectId={projectId} screenId={screenId}
            value={designId} onChange={(e, design) => {
                changeDesign(e, design, design?.variables?.reduce((acc, variable) => {
                    return { ...acc, [variable.id]: variable.value };
                }, {}))
            }} />
        <Box sx={{ flex: 1 }}>
            {children}
        </Box>
    </Stack>
};

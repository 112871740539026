import { StorePersist, usePersistStore } from '@/store/Store';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';
import { useShallow } from 'zustand/react/shallow';

export const IMG_COLOR = {
  ORANGE: '#FF4D00',
  ERROR: '#d32f2f',
} as const;

const selector = (store: StorePersist) => ({
  isDarkTheme: store.isDarkTheme,
  setDarkTheme: store.setDarkTheme,
});

export const AppThemeProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const { isDarkTheme, setDarkTheme } = usePersistStore(useShallow(selector));

  if (isDarkTheme === undefined) {
    setDarkTheme(prefersDarkMode);
  }

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiCssBaseline: {
            styleOverrides: {
              body: {
                scrollbarColor: '#6b6b6b #2b2b2b',
                '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
                  width: '8px',
                  borderRadius: 4,
                  backgroundColor: '#2b2b2b',
                },
                '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                  borderRadius: 4,
                  backgroundColor: '#6b6b6b',
                  minHeight: 24,
                  border: '3px solid #2b2b2b',
                },
                '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus':
                  {
                    backgroundColor: IMG_COLOR.ORANGE,
                  },
                '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active':
                  {
                    backgroundColor: IMG_COLOR.ORANGE,
                  },
                '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover':
                  {
                    backgroundColor: IMG_COLOR.ORANGE,
                  },
                '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
                  backgroundColor: '#2b2b2b',
                },
              },
            },
          },
        },
        palette: {
          mode: isDarkTheme ? 'dark' : 'light',
          primary: {
            main: IMG_COLOR.ORANGE,
          },
          secondary: {
            main: blueGrey[600],
          },
          error: {
            main: IMG_COLOR.ERROR,
          },
        },
      }),
    [isDarkTheme],
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

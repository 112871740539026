import { Box } from "@mui/material";

type Props = {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const TabPanel: React.FC<Props> = ({ children, index, value }) => {
    if (index !== value) {
        return null;
    }
    return <Box sx={{ pt: 1, pb: 1 }}>
        {children}
    </Box>
}
import { Typography, TypographyOwnProps } from '@mui/material';
import { useEffect, useRef } from 'react';

type Props = {
  text: string;
  delay?: number;
} & TypographyOwnProps;

export const Typewriter: React.FC<Props> = (props) => {
  const { text, delay = 25 } = props;
  const box = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!box.current) {
      return;
    }
    if (delay == 0) {
      box.current.innerHTML = text;
      return;
    }
    box.current.innerHTML = '';
    let timeout: NodeJS.Timeout;
    let i = 0;
    const type = () => {
      if (i < text.length) {
        box.current!.innerHTML += text.charAt(i);
        i++;
        timeout = setTimeout(type, delay);
      }
    };
    type();
    return () => {
      clearTimeout(timeout);
    };
  }, [text, delay]);

  return (
    <Typography width={`${text.length}ch`} {...props} ref={box}></Typography>
  );
};

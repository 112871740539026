import { ConnectingBox } from '@/components/ConnectingBox/ConnectingBox';
import { HeroLayout } from '@/components/Layouts/HeroLayout';
import { Store, useStore } from '@/store/Store';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';

const selector = (store: Store) => ({
  ready: store.ready,
  connect: store.connect,
  disconnect: store.disconnect,
  lastPing: store.lastPing,
  lastPong: store.lastPong,

});

export const SocketProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { ready, connect, disconnect, lastPing, lastPong } = useStore(selector, shallow);
  const pingBox = React.useRef<HTMLDivElement>(null);
  const [timeMs, setTimeMs] = React.useState(0);

  useEffect(() => {
    if (lastPong > lastPing) {
      setTimeMs(lastPong - lastPing);
      pingBox.current?.style.setProperty('transform', 'translateY(0)');
      setTimeout(() => {
        pingBox.current?.style.setProperty('transform', 'translateY(-100%)');
      }, 2000);
    }
  }, [lastPong, lastPing]);

  useEffect(() => {
    connect();
    return () => {
      disconnect();
    };
  }, [connect, disconnect]);
  return (
    <>
      {ready ? (
        <Box sx={{ overflow: 'hidden' }}>
          {children}
          <Stack p={1} ref={pingBox} sx={{
            alignContent: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            overflow: 'hidden',
            position: 'fixed',
            top: 0,
            left: 0,
            width: (theme) => theme.spacing(11),
            height: (theme) => theme.spacing(8),
            backgroundColor: (theme) => theme.palette.success.main,
            transform: 'translateY(-100%)',
            transition: 'transform 0.25s ease-in-out',
          }}>
            <Typography color='white' variant='h6' fontWeight='900' mb={-1}>MCC</Typography>
            <Typography color='white' variant='body2'>{timeMs}ms</Typography>
            <LinearProgress color='success' sx={{ position: 'absolute', left: 0, right: 0, top: 0 }} />
          </Stack>
        </Box >
      ) : (
        <>
          <HeroLayout>
            <ConnectingBox />
          </HeroLayout>
        </>
      )}
    </>
  );
};

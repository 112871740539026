import { ROUTES } from '@/router/constants';
import { MCSoccerEvent } from '@/types/soccer';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { SoccerEventStatus } from '../SoccerEventStatus/SoccerEventStatus';

type Props = {
  events: MCSoccerEvent[];
};

export const SoccerEventsTableView: React.FC<Props> = ({ events }) => {
  const navigate = useNavigate();

  const handleSelection = (model: GridRowSelectionModel) => {
    navigate(`${ROUTES.SOCCER_EVENTS}/${model[0]}`);
  };

  const yesNoCell = (params: GridRenderCellParams) => params.value ?
    <CheckCircleIcon color='success' titleAccess={params.value} /> :
    <CancelIcon color='error' />;

  const columns: readonly GridColDef<MCSoccerEvent>[] = [
    {
      field: 'status', headerName: 'Status', width: 120, renderCell: (params) => <>
        <SoccerEventStatus event={params.row} variant='compact' />
      </>
    },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'venue', headerName: 'Venue', flex: 1 },
    { field: 'ddeId', headerName: 'DDE', renderCell: yesNoCell },
    { field: 'streamId', headerName: 'Stream', renderCell: yesNoCell },
  ];

  return (
    <DataGrid
      sx={{ flex: '1 0 100%' }}
      rows={events}
      columns={columns}
      onRowSelectionModelChange={handleSelection}
    />
  );
};

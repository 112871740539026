import { Store, usePersistStore, useStore } from '@/store/Store';
import { TestFixture } from '@/store/TestPageSlice';
import { MccChangeDesignData, MccFixtureInfo, MccProject } from '@/types/mcc';
import { apiURL, fetchData } from '@/utils/fetchData';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import IosShareIcon from '@mui/icons-material/IosShare';
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { CommonDialog } from '../CommonDialog/CommonDialog';
import { DesignVars } from '../DesignVars/DesignVars';
import { PreviewScene } from '../PreviewScene/PreviewScene';
import { SceneButton } from '../SceneButton/SceneButton';

type Props = {
  projects: MccProject[];
  fixture: TestFixture;
  mccData: MccFixtureInfo;
};

const selector = (state: Store) => ({
  screens: state.screens,
  featchScreens: state.featchScreens,
  projectsFeatching: state.projectsFeatching,
  token: state.token,
});

export const TestFixturePlay: React.FC<Props> = ({
  fixture,
  projects,
  mccData,
}) => {
  const { screens, featchScreens, token } = useStore(useShallow(selector));

  const deleteFixture = usePersistStore((state) => state.deleteTestFixture);
  const [fixtureToDelete, setFixtureToDelete] = useState<string | undefined>();

  const modifyTestFixture = usePersistStore((state) => state.modifyTestFixture);

  const projectScreens = useMemo(() => {
    if (fixture?.projectId) {
      if (screens?.has(fixture.projectId)) {
        const screen = screens.get(fixture.projectId);
        if (screen) return screen;
      }
      featchScreens(fixture.projectId);
    }
    return undefined;
  }, [fixture?.projectId, screens, featchScreens]);

  const screen = useMemo(() => {
    if (fixture?.screenId && projectScreens) {
      return projectScreens.find((screen) => screen._id === fixture?.screenId);
    }
    return undefined;
  }, [fixture?.screenId, projectScreens]);

  if (!fixture || !projects) return null;
  const design = screen?.designs.find((d) => d._id === fixture.designId);

  const handleSendAction = (
    overrideDesignId: string | undefined = undefined,
  ) => {
    const designId = overrideDesignId || fixture.designId;

    const variables = Object.keys(fixture.variables).reduce((acc, key) => {
      if (!design?.variables?.some((v) => v.id === key)) {
        return acc;
      }
      return {
        ...acc,
        [key]: fixture.variables[key],
      };
    }, {});

    const data: MccChangeDesignData = {
      containerId: 0,
      project: fixture.projectId!,
      designId: designId!,
      variables,
    };
    fetchData({
      token,
      url: apiURL(`/stream/tennis/test-data/play/${fixture.id}/design`),
      method: 'POST',
      data,
    })
      .then(() => {
        enqueueSnackbar('Fixture Send', { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: 'error' });
      });
  };

  const disableScreenSelection =
    !fixture.projectId || (!projectScreens && !!fixture.screenId);



  return (
    <>
      <Stack gap={2} direction='row' mt={1} mb={2}>
        <Stack direction={'column'}>
          <Stack direction='row' justifyContent='end' gap={1}>
            <FormControl sx={{ minWidth: '25ch' }}>
              <InputLabel>Project</InputLabel>
              <Select
                autoWidth
                value={fixture?.projectId || ''}
                label='Project'
                onChange={(ev) => {
                  modifyTestFixture(fixture.id, {
                    projectId: ev.target.value as string,
                  });
                }}
              >
                {projects?.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: '25ch' }}>
              <InputLabel>Screen</InputLabel>
              <Select
                autoWidth
                disabled={disableScreenSelection}
                value={
                  projectScreens?.find((ps) => ps._id === fixture.screenId)
                    ? fixture?.screenId
                    : ''
                }
                label='Screen'
                onChange={(ev) => {
                  modifyTestFixture(fixture.id, {
                    screenId: ev.target.value,
                    designId: undefined,
                  });
                }}
              >
                {projectScreens?.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Divider />

          <FormControl sx={{ minWidth: '25ch', mt: 3 }}>
            <InputLabel>Default Fixture Design</InputLabel>
            <Select
              disabled={screen === undefined}
              autoWidth
              value={
                fixture?.designId &&
                  screen?.designs.find((d) => d._id === fixture.designId)
                  ? fixture?.designId
                  : ''
              }
              label='Designs'
              onChange={(ev) => {
                modifyTestFixture(fixture.id, { designId: ev.target.value });
              }}
            >
              {screen?.designs?.map((item) => (
                <MenuItem key={item._id} value={item._id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Stack
            direction='row'
            gap={1}
            justifyContent='stretch'
            sx={{ mt: 1, mb: 2 }}
          >

            <Button
              sx={{ flexGrow: 1 }}
              size='large'
              disabled={
                !fixture ||
                !fixture.projectId ||
                !fixture.screenId ||
                !fixture.designId
              }
              variant='contained'
              color='primary'
              startIcon={<IosShareIcon />}
              onClick={() => handleSendAction()}
            >
              Send Default
            </Button>
          </Stack>

          <Stack gap={0.5}>
            <Typography variant='overline'>Quick Send</Typography>
            {screen &&
              screen.designs.map((design) => {
                return (
                  <Button
                    variant='outlined'
                    color='info'
                    key={design._id}
                    onClick={() => {
                      handleSendAction(design._id);
                    }}
                  >
                    {design.name}
                  </Button>
                );
              })}
          </Stack>

          <Button
            sx={{ mt: 3 }}
            size='large'
            variant='contained'
            color='error'
            startIcon={<HighlightOffIcon />}
            onClick={() => {
              setFixtureToDelete(fixture.id);
            }}
          >
            Delete Fixture
          </Button>
        </Stack>
        {mccData && (
          <Stack gap={1}>
            <Box
              width='500px'
              alignContent='start'
              justifyContent='top'
              sx={{ aspectRatio: '16/9' }}
            >
              <PreviewScene mccData={mccData} />
            </Box>
            <SceneButton mccData={mccData} />
            <DesignVars design={design} fixtureId={fixture.id} />
          </Stack>
        )}
      </Stack >
      <CommonDialog
        open={!!fixtureToDelete}
        handleClose={() => {
          setFixtureToDelete(undefined);
        }}
        message='Delete Fixture'
      >
        <Button
          onClick={() => {
            deleteFixture(fixtureToDelete!);
            setFixtureToDelete(undefined);
          }}
        >
          Delete
        </Button>
      </CommonDialog>
    </>
  );
};

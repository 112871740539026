import { usePersistStore } from "@/store/Store";
import { MccDesign, MccVariableType, ProjectColor } from "@/types/mcc";
import { Stack, TextField, Typography } from "@mui/material";
import { v4 as randomUUID } from 'uuid';

type Props = {
    design?: MccDesign;
    fixtureId: string;
}

const createColor = (color: string): ProjectColor => {
    const rnd = randomUUID();
    return {
        _id: rnd,
        color,
        createdAt: new Date().toISOString(),
        name: rnd,
        projectId: rnd,
        updatedAt: new Date().toISOString(),
    }
}

export const DesignVars: React.FC<Props> = ({ fixtureId, design }) => {

    const modifyTestFixture = usePersistStore((state) => state.modifyTestFixture);
    const fixture = usePersistStore((state) => state.testFixtures.find((fixture) => fixture.id === fixtureId));

    if (!design || !design.variables || design.variables.length === 0) {
        return <></>
    }

    return (
        <>
            <Typography variant='h6' gutterBottom>
                {design.name}
            </Typography>
            <Stack gap={1}>
                {design.variables.map((variable) => {
                    const val = fixture?.variables?.[variable.id] ? fixture?.variables[variable.id] : variable.value;
                    const onChange = (e: { target: { value: unknown } }) => {
                        if (!e.target) return;
                        modifyTestFixture(fixtureId, {
                            variables: { ...fixture?.variables, [variable.id]: e.target.value }
                        });
                    }
                    switch (variable.type) {
                        case MccVariableType.Number:
                            return <TextField
                                key={`variable-${variable.id}`}
                                type="number"
                                label={variable.name}
                                variant='outlined'
                                value={val}
                                size="small"
                                onChange={(e) => onChange({ ...e, target: { value: Number(e.target.value) } })}
                            />
                        case MccVariableType.ProjectColor:
                            return <TextField
                                key={`variable-${variable.id}`}
                                label={variable.name}
                                variant='outlined'
                                value={(val as ProjectColor).color}
                                size="small"
                                onChange={(e) => onChange({ ...e, target: { value: createColor(e.target.value) } })}
                            />

                        default:
                            return (
                                <TextField
                                    key={`variable-${variable.id}`}
                                    label={variable.name}
                                    variant='outlined'
                                    value={val}
                                    size="small"
                                    onChange={onChange}
                                />
                            );
                    };

                })}
            </Stack>
        </>
    );
};
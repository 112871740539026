import { DDETennisEvent } from '@/types/dde';

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

export function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export const tennisEventName = (
  event?: DDETennisEvent,
  type: 'normal' | 'short' | 'title' = 'normal',
) => {
  if (!event) return 'Unknown';
  const p1 = event.teamA.team.player1;
  const p2 = event.teamB.team.player1;
  if (type === 'short') {
    return `${p1.lastName} vs ${p2.lastName}`;
  } else if (type === 'title') {
    const pa = `${p1.firstName.substring(0, 1)}. ${p1.lastName}`.toUpperCase();
    const pb = `${p2.firstName.substring(0, 1)}. ${p2.lastName}`.toUpperCase();
    return `${pa} vs ${pb}`;
  }
  return `${p1.firstName} ${p1.lastName} [${p1.country}] vs ${p2.firstName} ${p2.lastName} [${p2.country}]`;
};

export const getFlagUrl = (country: string) => {
  return `/flags/${country.toLowerCase()}.png`;
}
import { MccChangeDesignData, MccDesign, MccDesignVariableType } from '@/types/mcc';
import { Button, Paper, Stack } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { MCCDesignSelector } from '../MCCombo/MCCDesignSelector';
import { MCDesignVariables } from '../MCDesignVariables/MCDesignVariables';

type Props = {
    changeDesign: (data: MccChangeDesignData) => void;
}

export const ManualDesignSelector: React.FC<Props> = ({ changeDesign }) => {
    const [projectId, setProjectId] = useState<string>();
    const [screenId, setScreenId] = useState<string>();
    const [design, setDesign] = useState<MccDesign | undefined>();
    const [variables, setVariables] = useState<Record<string, MccDesignVariableType>>();

    return <Stack>
        <MCCDesignSelector projectId={projectId} screenId={screenId} designId={design?._id || ''}
            changeProject={setProjectId}
            changeScreen={setScreenId}
            changeDesign={(_id, design, vars) => {
                setDesign(design);
                setVariables(vars);
            }} >
            <Button variant="contained" onClick={() => {
                if (design && projectId && screenId) {
                    changeDesign({ containerId: 0, project: projectId, designId: design._id, variables });
                } else {
                    enqueueSnackbar('Please select project, screen and design', { variant: 'error' });
                }
            }
            } >Play</Button>
        </MCCDesignSelector>

        {(design && projectId && screenId) && <>
            <Paper elevation={4} sx={{ p: 1, mt: 2 }}>
                <MCDesignVariables
                    projectId={projectId}
                    screenId={screenId}
                    designId={design._id}
                    values={variables}
                    onChange={({ id, value }) => {
                        setVariables({ ...variables, [id]: value });
                    }}
                />
            </Paper>
        </>}
    </Stack>
};

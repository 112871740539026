import { DDETennisEvent, DDETournament } from '@/types/dde';
import { apiURL, fetchData } from '@/utils/fetchData';
import { StateCreator } from 'zustand';
import { UserSlice } from './UserSlice';

export type TournamentsDataSlice = {
  tournaments?: DDETournament[];
  featchTournaments: () => void;

  events?: DDETennisEvent[];
  featchEvents: (tournamentId: string) => void;
};

export const createTournamentsDataSlice: StateCreator<
  TournamentsDataSlice & UserSlice,
  [['zustand/devtools', never]],
  [],
  TournamentsDataSlice
> = (set, get) => ({
  featchTournaments: async () => {
    const tournaments = await fetchData<DDETournament[]>({
      url: apiURL('/stream/tennis/tournaments'),
      method: 'GET',
      token: get().token,
    });
    set({ tournaments }, false, 'TDS tournaments featched');
  },
  featchEvents: async (_tournamentId: string) => {
    const events = await fetchData<DDETennisEvent[]>({
      url: apiURL(`/stream/tennis/tournaments/${_tournamentId}/events`),
      method: 'GET',
      token: get().token,
    });
    set({ events }, false, 'TDS events featched');
  },
});

import { PageHeader } from '@/components/PageHeader/PageHeader';
import { SoccerEventsTableView } from '@/components/Soccer/SoccerEventsTableView/SoccerEventsTableView';
import { Store, useStore } from '@/store/Store';
import { Paper, Stack, Typography } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';
import { SoccerEvent } from './Event/SoccerEvent';

const selector = (store: Store) => ({
  featchSoccerEvents: store.featchSoccerEvents,
  soccerEvents: store.soccerEvents,
});

export const SoccerEvents = () => {
  const { eventId } = useParams();
  const { featchSoccerEvents, soccerEvents } = useStore(useShallow(selector));
  const isFeatching = useStore((state) => state.featchingSoccerEvents);

  useEffect(() => {
    featchSoccerEvents();
    const interval = setInterval(() => {
      featchSoccerEvents();
    }, import.meta.env.VITE_API_LIVE_EVENT_INTERVAL * 1000);
    return () => {
      clearInterval(interval);
    }
  }, [featchSoccerEvents]);

  const event = useMemo(() => {
    if (eventId) {
      return soccerEvents?.find((event) => event.id === eventId);
    }
  }, [eventId, soccerEvents]);


  let content: React.ReactNode;
  if (soccerEvents?.length === 0) {
    content = <Stack sx={{
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Typography variant='h5'>No Live Events at the moment</Typography>
    </Stack>
  } else if (event) {
    content = <SoccerEvent event={event} />
  } else if (soccerEvents) {
    content = <Paper sx={{ flex: 1, overflow: 'hidden' }} elevation={1}>
      <SoccerEventsTableView events={soccerEvents} />
    </Paper>
  }

  return (
    <Stack sx={{ height: '100%' }}>
      {!eventId && <PageHeader title='Soccer Events' isLoading={isFeatching} />}
      {content}
    </Stack>
  );
};

import { PageHeader } from '@/components/PageHeader/PageHeader';
import { TestFixturePlay } from '@/components/TestFixtures/TestFixturePlay';
import { TestFixturesList } from '@/components/TestFixtures/TestFixturesList';
import { usePersistStore, useStore } from '@/store/Store';
import { MccFixtureInfo } from '@/types/mcc';
import { apiURL, fetchData } from '@/utils/fetchData';
import { Paper, Stack } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

export const TestFixtureView: React.FC = () => {
  const featchProjects = useStore((state) => state.featchProjects);
  const token = useStore((state) => state.token);
  const projects = useStore((state) => state.projects);
  const fixtures = usePersistStore((state) => state.testFixtures);
  const { fixtureId } = useParams();

  const fixture = useMemo(() => {
    return fixtures?.find((fixture) => fixture.id === fixtureId);
  }, [fixtureId, fixtures]);

  const [mccData, setMccData] = useState<MccFixtureInfo | undefined>(undefined);
  useEffect(() => {
    if (!fixtureId) return;
    const load = async () =>
      await fetchData<MccFixtureInfo>({
        token,
        url: apiURL(`/stream/tennis/test-data/play/${fixtureId}`),
      });
    load().then((data) => setMccData(data));
  }, [fixtureId, token]);

  useEffect(() => {
    featchProjects();
  }, [featchProjects]);

  return (
    <>
      <PageHeader
        title={fixture ? `Fixture: ${fixture.id}` : 'DEV Test Fixture'}
        isLoading={!projects}
      />
      <Stack direction='row' alignContent='center' gap={2}>
        <TestFixturesList />
        {fixture && mccData ? (
          <>
            <Paper elevation={1} sx={{ p: 2 }}>
              <TestFixturePlay
                fixture={fixture}
                mccData={mccData}
                projects={projects!}
              />
            </Paper>
          </>
        ) : null}
      </Stack>
    </>
  );
};

import { LoadingButton } from '@/components/LoadingButton/LoadingButton';
import { Typewriter } from '@/components/Typewriter/Typewriter';
import { STRINGS } from '@/strings';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Stack, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { AuthApiError } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

const usernameSchema = z
  .string({ required_error: STRINGS.FORM_EMAIL_REQUIRED })
  .email(STRINGS.FORM_EMAIL_INVALID);
const passwordSchema = z.string().min(1, STRINGS.FORM_PASSWORD_REQUIRED);

const signInSchema = z.object({
  username: usernameSchema,
  password: passwordSchema,
});

const OKTA_TRANSACTION_SUCCESS = 'SUCCESS';

type SignInSchema = z.infer<typeof signInSchema>;

export const SignInForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignInSchema>({
    resolver: zodResolver(signInSchema),
    mode: 'onTouched',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);
  const { oktaAuth } = useOktaAuth();

  const onSubmit: SubmitHandler<SignInSchema> = async (data) => {
    setFormError(null);
    setIsLoading(true);

    try {
      const transaction = await oktaAuth.signInWithCredentials(data);

      if (transaction.status !== OKTA_TRANSACTION_SUCCESS) {
        throw `Unexpected transaction status: ${transaction.status}`;
      }

      oktaAuth.signInWithRedirect({ sessionToken: transaction.sessionToken });
    } catch (error) {
      if (error instanceof AuthApiError) {
        setFormError(STRINGS.SIGN_IN_WRONG_CREDENTIALS);
        return;
      }

      setFormError(STRINGS.GENERIC_ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form role='form' onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ mb: (theme) => theme.spacing(8) }}>
        <Typewriter
          variant='h4'
          sx={{ mb: (theme) => theme.spacing(1) }}
          text={STRINGS.HELLO_MCCS}
        />
        <Typography variant='h6'>{STRINGS.HELLO_MCCS_CAPTION}</Typography>
      </Box>
      <Stack sx={{ mx: 'auto' }} gap={2}>
        <Typography variant='h5'>Sign in</Typography>
        <TextField
          {...register('username')}
          error={!!errors.username || !!formError}
          label={STRINGS.FORM_EMAIL}
          variant='outlined'
          helperText={errors.username?.message}
        />
        <TextField
          {...register('password')}
          error={!!errors.password || !!formError}
          label={STRINGS.FORM_PASSWORD}
          type='password'
          variant='outlined'
          helperText={errors.password?.message}
        />
        {formError && <Typography color='error'>{formError}</Typography>}
        <LoadingButton
          sx={{
            width: 'fit-content',
            padding: 1,
          }}
          variant='contained'
          type='submit'
          isLoading={isLoading}
          aria-label={STRINGS.SIGN_IN}
        >
          {STRINGS.SIGN_IN}
        </LoadingButton>
      </Stack>
    </form>
  );
};

import { API_METHODS, DEFAULT_HEADERS } from '@/constants/requests';
import { ApiError } from '@/utils/ApiError';

export interface FetchDataOptions {
  token?: string;
  url: string;
  method?: (typeof API_METHODS)[keyof typeof API_METHODS];
  data?: Record<string, unknown>;
}

export const apiURL = (path: string): string => {
  return `${import.meta.env.VITE_API_URL}${path}`;
};

export const fetchData = async <T = unknown>({
  token,
  url,
  method = API_METHODS.GET,
  data = {},
}: FetchDataOptions): Promise<T> => {
  if (!token) {
    throw new Error('Missing auth token');
  }
  const res = await fetch(url, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      ...DEFAULT_HEADERS,
    },
    method,
    body: method === API_METHODS.GET ? undefined : JSON.stringify(data),
  });

  if (!res.ok) {
    const error = await ApiError.parseFromResponse(res);
    throw error;
  }

  return await res.json();
};

import { DDETennisEvent } from "@/types/dde";
import { MCSoccerEvent } from "@/types/soccer";
import { getFlagUrl } from "@/utils/Utils";
import { keyframes } from '@emotion/react';
import { Box } from "@mui/material";
import { CSSProperties } from "react";

type Props = {
    tennis?: never;
    soccer: MCSoccerEvent
} | {
    tennis: DDETennisEvent;
    soccer?: never;
};

export const PlayerVsFlags: React.FC<Props> = ({ tennis, soccer }) => {

    const showFlagsAnim = keyframes`
    0% { opacity: 0; }
    100% { opacity: 0.2; } }`;

    const imageStyle: CSSProperties = {
        objectFit: 'cover',
        position: 'absolute',
        width: '100%',
        filter: 'blur(50px)'
    }

    let flagAUrl = '/bg.png';
    let flagBUrl = '/bg.png';
    if (tennis) {
        flagAUrl = getFlagUrl(tennis.teamA.team.player1.country);
        flagBUrl = getFlagUrl(tennis.teamB.team.player1.country);
    } else if (soccer?.raw.streamEvent?.poster) {
        flagAUrl = soccer.raw.streamEvent.poster;
        flagBUrl = flagAUrl;
    }

    return (
        <Box id='player-vs-flags' sx={{
            pointerEvents: 'none',
            position: 'absolute',
            animation: `${showFlagsAnim} 1s ease-in-out 0s 1 normal forwards`,
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 1,
            opacity: 0.0,
            overflow: 'hidden',
        }}>
            {soccer ? <>
                <img src={flagAUrl} style={{ ...imageStyle }} />
            </> : <>
                <img src={flagAUrl} style={{ ...imageStyle, transform: 'translate(-50%, -0%)' }} />
                <img src={flagBUrl} style={{ ...imageStyle, transform: 'translate(50%, -0%)' }} />
            </>}
        </Box>
    );
}
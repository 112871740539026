export const ROUTES = {
  HOME: '/',
  SIGN_IN: '/sign-in',
  TOURNAMENTS: '/tournaments',
  LIVE_EVENTS: '/live-events',
  SOCCER_EVENTS: '/soccer-events',
  SEQUENCE_TEMPLATE: '/sequence-template',
  TEST_VIEW: '/test-view',
  SETTINGS: '/settings',
  OKTA_LOGIN_CALLBACK: '/auth/okta',
  SCENES: '/scenes',
  STREAM_SCENES: '/stream-scenes',
};

import { ROUTES } from '@/router/constants';
import { StorePersist, usePersistStore } from '@/store/Store';
import AddIcon from '@mui/icons-material/Add';
import ScoreboardIcon from '@mui/icons-material/Scoreboard';
import {
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';

const selector = (store: StorePersist) => ({
  addTestFixture: store.addTestFixture,
  testFixtures: store.testFixtures,
});

type Props = {
  fixtureId?: string;
};

export const TestFixturesList: React.FC<Props> = ({ fixtureId = '' }) => {
  const { addTestFixture, testFixtures } = usePersistStore(selector, shallow);

  const [newFixtureId, setNewFixtureId] = useState('');
  const [newFixtureIdError, setNewFixtureIdError] = useState<
    string | undefined
  >(undefined);

  const navigate = useNavigate();

  const handleAddFixture = () => {
    const id = newFixtureId;
    if (!id || id === '') {
      setNewFixtureIdError('Fixture ID is required');
      return;
    }
    if (testFixtures?.find((fixture) => fixture.id === id)) {
      setNewFixtureIdError('Fixture ID already exists');
      return;
    }
    addTestFixture({ id, variables: {} });
    setNewFixtureId('');
  };

  const handleFixtureClick = (fixtureId: string) => {
    navigate(`${ROUTES.TEST_VIEW}/${fixtureId}`);
  };

  return (
    <>
      <Stack direction={'column'}>
        <Stack
          direction='row'
          justifyContent='end'
          mt={1}
          mb={2}
          gap={1}
          alignItems='stretch'
        >
          <TextField
            id='new-fixture-field'
            label='Fixture ID'
            variant='outlined'
            error={!!newFixtureIdError}
            helperText={newFixtureIdError}
            value={newFixtureId}
            onChange={(ev) => {
              setNewFixtureId(ev.currentTarget.value);
              setNewFixtureIdError(undefined);
            }}
          />
          <Button
            size='large'
            variant='outlined'
            color='info'
            onClick={handleAddFixture}
          >
            <AddIcon />
          </Button>
        </Stack>
        <Divider />

        <List dense={true} sx={{ mt: 1 }}>
          {testFixtures?.map((fixture) => (
            <ListItemButton
              key={fixture.id}
              selected={fixtureId ? fixture.id == fixtureId : false}
              onClick={() => handleFixtureClick(fixture.id)}
            >
              <ListItemIcon>
                <ScoreboardIcon />
              </ListItemIcon>
              <ListItemText primary={fixture.id} />
            </ListItemButton>
          ))}
        </List>
      </Stack>
    </>
  );
};

import { LiveEventsTableView } from '@/components/LiveEventsTableView/LiveEventsTableView';
import { PageHeader } from '@/components/PageHeader/PageHeader';
import { useStore } from '@/store/Store';
import { DDETennisLiveEvent } from '@/types/dde';
import { apiURL, fetchData } from '@/utils/fetchData';
import { Paper, Stack, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

export const LiveEvents = () => {
  const token = useStore((state) => state.token);
  const [events, setEvents] = useState<DDETennisLiveEvent[] | undefined | void>();

  useEffect(() => {
    let isLoading = true;
    let interval: NodeJS.Timeout;
    const load = async () => {
      const response = await fetchData<DDETennisLiveEvent[]>({
        url: apiURL('/stream/tennis/live-stages'),
        token,
      }).catch(() => {
        enqueueSnackbar('Failed to load live events', { variant: 'error' });
      });
      if (isLoading) {
        setEvents(response);
        interval = setTimeout(load, import.meta.env.VITE_API_LIVE_EVENT_INTERVAL * 1000);
      }
    };
    load();
    return () => {
      isLoading = false;
      clearTimeout(interval);
    }
  }, [token]);

  let content: React.ReactNode;

  if (!events) {
    content = null;
  }
  else if (events.length === 0) {
    content = <Stack sx={{
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Typography variant='h5'>No Live Events at the moment</Typography>
    </Stack>
  } else {
    content = <Paper sx={{ flex: 1, overflow: 'hidden' }} elevation={1}>
      <LiveEventsTableView liveEvents={events} />
    </Paper>
  }

  return (
    <>
      <Stack sx={{ height: '100%' }}>
        <PageHeader title='Live Events' isLoading={!events} />
        {content}
      </Stack>
    </>
  );
};

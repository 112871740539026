import { ROUTES } from '@/router/constants';
import { MccFixtureInfo } from '@/types/mcc';
import { keyframes } from '@emotion/react';
import TheatersIcon from '@mui/icons-material/Theaters';
import {
  Button,
  ButtonProps,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { FC } from 'react';

type Props = ButtonProps & {
  mccData: MccFixtureInfo | undefined;
  eventId?: string;
  streamId?: string;
};
export const SceneButton: FC<Props> = ({ mccData, eventId, streamId, ...props }) => {
  const theme = useTheme();
  const isLiveKeyframes = keyframes`
  0% { border-color: ${theme.palette.info.main} }
  100% { border-color: ${theme.palette.success.main} } }`;

  const mccURL = mccData
    ? `${import.meta.env.VITE_MCC_URL}/${mccData.url}`
    : '';

  let hrefURL = mccURL;
  if (eventId) {
    if (streamId) {
      hrefURL = `${ROUTES.STREAM_SCENES}/${eventId}/${streamId}`;
    } else {
      hrefURL = `${ROUTES.SCENES}/${eventId}`;
    }
  }

  return (
    <Button
      sx={{
        maxWidth: '30ch',
        animation: mccData
          ? `${isLiveKeyframes} 1s infinite alternate`
          : undefined,
      }}
      disabled={!mccData ? true : props.disabled}
      href={hrefURL}
      target='_blank'
      color='info'
      variant='outlined'
      startIcon={
        !mccData ? (
          <CircularProgress color='inherit' size={'1em'} />
        ) : (
          <TheatersIcon />
        )
      }
    >
      {mccData ? (
        <Stack spacing={-1} sx={{ color: (theme) => theme.palette.grey[500], width: '100%' }}>
          <Typography variant='button'>{eventId ? 'FS Preview' : 'MCC Scene'}</Typography>
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              direction: 'rtl',
            }}
            variant='caption'>{eventId ? hrefURL : mccData?.url}</Typography>
        </Stack>
      ) : (
        'Loading...'
      )}
    </Button>
  );
};

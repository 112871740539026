import { LoadingButton } from '@/components/LoadingButton/LoadingButton';
import { FormInputDropdown } from '@/components/ReactHookFormSelect/FormInputDropdown';
import { Typewriter } from '@/components/Typewriter/Typewriter';
import { STRINGS } from '@/strings';
import {
  SPORTS,
  SequenceTemplate,
  sequenceTemplateSchema,
} from '@/types/sequences';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Box,
  Dialog,
  FormControl,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { v4 as randomUUID } from 'uuid';

type Props = {
  open: boolean;
  template?: SequenceTemplate;
  handleClose: (template?: SequenceTemplate) => void;
};

export const TemplateDialog: React.FC<Props> = ({
  open,
  template,
  handleClose,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SequenceTemplate>({
    resolver: zodResolver(sequenceTemplateSchema),
    mode: 'onTouched',
    defaultValues: template ?? {
      id: randomUUID(),
      name: '',
      sport: SPORTS[0],
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState<string | null>(null);

  const onSubmit: SubmitHandler<SequenceTemplate> = async (data) => {
    console.log('submit', data);
    setFormError(null);
    setIsLoading(true);
    setTimeout(() => {
      handleClose(data);
    }, 1000);
  };

  const close = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const saveString = template ? STRINGS.SAVE : STRINGS.CREATE;

  const options = SPORTS.map((sport) => ({ value: sport, label: sport }));

  return (
    <Dialog open={open} onClose={close}>
      <Paper elevation={1} sx={{ padding: 3, textAlign: 'left' }}>
        <form role='form' onSubmit={handleSubmit(onSubmit)}>
          <Box mb={2}>
            <Typewriter
              variant='h5'
              sx={{ mb: (theme) => theme.spacing(1) }}
              text={template ? 'Edit sequence' : 'Create new sequence'}
            />
            {template && (
              <Typography variant='body1'>{template.name}</Typography>
            )}
          </Box>
          <Stack direction='row' sx={{ mx: 'auto' }} gap={2}>
            <FormControl sx={{ minWidth: 160 }}>
              <TextField
                {...register('name')}
                label='Name'
                id='name'
                error={!!errors.name || !!formError}
                variant='outlined'
                helperText={errors.name?.message}
              />
            </FormControl>

            <FormInputDropdown
              formProps={{ sx: { minWidth: 160 } }}
              control={control}
              name='sport'
              options={options}
              label='Sports'
            />

            {formError && <Typography color='error'>{formError}</Typography>}
            <LoadingButton
              sx={{
                width: 'fit-content',
                padding: 1,
              }}
              variant='contained'
              type='submit'
              isLoading={isLoading}
              aria-label={saveString}
            >
              {saveString}
            </LoadingButton>
          </Stack>
        </form>
      </Paper>
    </Dialog>
  );
};

import { DDECompetition } from '@/types/dde';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  competitions: DDECompetition[];
};

export const TennisCompetitionsTableView: React.FC<Props> = ({ competitions }) => {
  const navigate = useNavigate();

  const handleSelection = (model: GridRowSelectionModel) => {
    navigate(`${model[0]}/events`);
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Type', flex: 0.3 },
    { field: 'type', headerName: 'Date', flex: 0.3 },
    { field: 'licencingProperty', headerName: 'Licencing Property', flex: 0.3 },
    { field: 'startDate', headerName: 'Start Date', flex: 0.3 },
    { field: 'endDate', headerName: 'End Date', flex: 0.3 },
  ];

  const rows = competitions.map((ev) => ({
    id: ev.competitionId,
    type: ev.competitionType,
    licencingProperty: ev.licencingProperty,
    startDate: ev.startDate,
    endDate: ev.endDate,
  }));

  return (
    <DataGrid
      sx={{ flex: '1 0 100%' }}
      rows={rows}
      columns={columns}
      onRowSelectionModelChange={handleSelection}
    />
  );
};

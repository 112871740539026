import { Sport } from "./sequences";

export type MCSoccerEvent = {
    id: string;
    name: string;
    venue: string;
    ddeId?: string;
    streamId?: string;
    status?: DDESoccerEventStatus;
    raw: SoccerEvent;
}

export type SoccerEvent = {
    id: string;
    streamEvent?: StreamEvent;
    ddeEvent?: DDESoccerEvent;
} & (
        {
            streamEvent: StreamEvent;
            ddEvent: never
        } | {
            streamEvent: never;
            ddeEvent: DDESoccerEvent
        });

// stream event

export enum DDESoccerEventStatus {
    Scheduled = 'Scheduled',//
    Live = 'Live',//
    Completed = 'Completed',
    Postponed = 'Postponed',//
    Abandoned = 'Abandoned',//
    Cancelled = 'Cancelled',//
    Suspended = 'Suspended',
    Unverified = 'Unverified',//
}

export type DDESoccerEvent = {
    id: string;
    name: string;
    status: DDESoccerEventStatus;
    collectionStatus: string;
    type: string;
    coverageLevel: string;
    startDateUTC: string;
    teams: {
        id: string;
        name: string;
        club: {
            id: string;
            name: string;
        };
        category: {
            id: number;
            name: string;
        };
        homeTeam: boolean;
    }[];
    stage: {
        id: string;
        name: string;
        season: {
            id: string;
            name: string;
            startDateUTC: string;
            endDateUTC: string;
            competition: {
                id: string;
                name: string;
                type: string;
                category: {
                    id: number;
                    name: string;
                };
                location: {
                    name: string;
                    country: unknown;
                    continent: unknown;
                };
                latestComment: string;
                commentCount: number;
            };
        };
        startDateUTC: string;
        endDateUTC: string;
    };
    venue: {
        id: string;
        name: string;
        location: {
            name: string;
            country: {
                name: string;
            };
            continent: {
                name: string;
            };
        };
    };
    usages: string[];
};

export type TournamentProperty = {
    id: number;
    name: string;
    sport: Sport;
}

export type Tournament = {
    id: number;
    name: string;
    property: TournamentProperty;
}

export type StreamEvent = {
    id: number;
    title: string;
    tournament: Tournament;
    location?: string;
    description?: string;
    externalId?: string;
    is4k: boolean;
    multiAudio: boolean;
    poster?: string;
    imageAssets: {
        poster: string;
    };
    audioTracks: {
        type: string;
        tracks: unknown[];
    };
    updatedAt: string;
    startDate: string;
    endDate: string;
    closedCaption: boolean;
    externalAssetId?: {
        id: string;
        provider: string;
    };
    arenaId: string;
    clientConfiguration: string[];
    imgdataID?: string;
}
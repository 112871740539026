export const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
} as const;

export const DEFAULT_REQUEST_OPTIONS: RequestInit = {
  mode: 'cors',
} as const;

export const API_METHODS = {
  GET: 'GET',
  POST: 'POST',
} as const;

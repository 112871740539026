import { StateCreator } from 'zustand';

export type PersistStorageSlice = {
  isDarkTheme?: boolean;
  setDarkTheme: (isDarkMode: boolean) => void;
  lastProjectId?: string;
  lastScreenId?: string;
  setLastProjectId: (projectId: string) => void;
  setLastScreenId: (screenId: string) => void;
};

export const createPersistStorageSlice: StateCreator<
  PersistStorageSlice,
  [['zustand/devtools', never]],
  [['zustand/persist', PersistStorageSlice]],
  PersistStorageSlice
> = (set) => ({
  setDarkTheme: (isDarkTheme) => {
    set({ isDarkTheme }, false, 'STR Is Dark Theme set');
  },
  setLastProjectId: (lastProjectId) => {
    set({ lastProjectId }, false, 'STR Last Project Id set');
  },
  setLastScreenId: (lastScreenId) => {
    set({ lastScreenId }, false, 'STR Last Screen Id set');
  },
});

import { PageHeader } from '@/components/PageHeader/PageHeader';
import { TennisCompetitionsTableView } from '@/components/Tournaments/TennisCompetitionsTableView/TennisCompetitionsTableView';
import { TennisEvent } from '@/components/Tournaments/TennisEvent/TennisEvent';
import { TennisEventsTableView } from '@/components/Tournaments/TennisEventsTableView/TennisEventsTableView';
import { TournamentsTableView } from '@/components/Tournaments/TournamentsTableView/TournamentsTableView';
import { ROUTES } from '@/router/constants';
import { Store, useStore } from '@/store/Store';
import { tennisEventName } from '@/utils/Utils';
import { Paper, Stack } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/react/shallow';

const selector = (state: Store) => ({
  tournaments: state.tournaments,
  featchTournaments: state.featchTournaments,
  events: state.events,
  featchEvents: state.featchEvents,
});

enum VIEW_STATE {
  TOURNAMENTS = 'Tournaments',
  COMPETITIONS = 'Competitions',
  EVENTS = 'Events',
  EVENT = 'Event',
}

export const TournamentsView = () => {
  const { tournamentId, competitionId, eventId } = useParams();
  const { tournaments, featchTournaments, events, featchEvents } = useStore(
    useShallow(selector),
  );

  const viewState = useMemo(() => {
    if (eventId) {
      return VIEW_STATE.EVENT;
    }
    if (tournamentId) {
      if (competitionId) {
        return VIEW_STATE.EVENTS;
      }
      return VIEW_STATE.COMPETITIONS;
    }
    return VIEW_STATE.TOURNAMENTS;
  }, [tournamentId, competitionId, eventId]);

  useEffect(() => {
    featchTournaments();
  }, [featchTournaments]);

  useEffect(() => {
    if (tournamentId) {
      featchEvents(tournamentId);
    }
  }, [featchEvents, tournamentId]);

  const tournament = useMemo(() => {
    if (tournaments && tournamentId) {
      return tournaments.find(
        (tournament) => tournament.identifier === parseInt(tournamentId),
      );
    }
    return undefined;
  }, [tournamentId, tournaments]);

  const competition = useMemo(() => {
    if (tournament && competitionId) {
      return tournament.competitions.find(
        (competition) => competition.competitionId === competitionId,
      );
    }
    return undefined;
  }, [competitionId, tournament]);

  const event = useMemo(() => {
    if (events && eventId) {
      return events.find((event) => event.eventId === eventId);
    }
    return undefined;
  }, [eventId, events]);

  const isLoading = !tournaments || (tournamentId !== undefined && !events);
  const content: React.ReactNode = useMemo(() => {

    switch (viewState) {
      case VIEW_STATE.EVENT:
        if (!event) {
          return null;
        }
        return <TennisEvent event={event} />;
      case VIEW_STATE.EVENTS:
        if (!events) {
          return null;
        }
        return <TennisEventsTableView events={events} />;
      case VIEW_STATE.COMPETITIONS:
        if (!tournament) {
          return null;
        }
        return <TennisCompetitionsTableView competitions={tournament.competitions} />;
      default:
        if (!tournaments) {
          return null;
        }
        return <TournamentsTableView tournaments={tournaments} />;
    }
  }, [event, events, tournament, tournaments, viewState]);

  return (
    <Stack sx={{ height: '100%' }}>
      <PageHeader title={viewState} isLoading={isLoading} />

      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 2 }}>
        <Link underline='hover' to={ROUTES.TOURNAMENTS} component={RouterLink}>
          Tournaments
        </Link>
        {tournamentId && (
          <Link
            underline='hover'
            to={`${ROUTES.TOURNAMENTS}/${tournamentId}/competitions`}
            component={RouterLink}
          >
            {tournament?.tournamentName}
          </Link>
        )}
        {competition && <Link
          underline='hover'
          to={`${ROUTES.TOURNAMENTS}/${tournamentId}/competitions/${competitionId}/events`}
          component={RouterLink}
        >
          {competition.competitionId}</Link>}
        {event && <Link underline='none'>{tennisEventName(event)}</Link>}
      </Breadcrumbs>

      <Paper sx={{ flex: 1, overflow: 'hidden' }} elevation={eventId ? 0 : 1}>
        {content}
      </Paper>
    </Stack>
  );
};

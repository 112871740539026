export type DDETournament = {
  identifier: number;
  tournamentName: string;
  sport: string;
  city: string;
  location: string;
  startDate: string;
  endDate: string;
  competitions: DDECompetition[];
};

export type DDECompetition = {
  organisation: string,
  externalId: string,
  endDate: string,
  competitionType: string,
  singlesDrawSize: number,
  singlesQualifyingDrawSize: number,
  drawPoolSize: number,
  doublesDrawSize: number,
  doublesQualifyingDrawSize: number,
  competitionId: string,
  licencingProperty: string,
  startDate: string,
  drawNumPools: number
}

export enum DDETennisEventStatus {
  NOT_STARTED = 'NotStarted',
  IN_PROGRESS = 'InProgress',
  SUSPENDED = 'Suspended',
  FINISHED = 'Finished'
}

export type DDETennisEvent = {
  startTime: {
    status: string;
    time: string;
  };
  courtSeq: number;
  courtName: string;
  startTimeText: string;
  teamA: {
    status: string;
    team: {
      player1: {
        id: string;
        firstName: string;
        lastName: string;
        country: string;
      };
      entryType: string;
      seed?: number;
    };
  };
  teamB: {
    status: string;
    team: {
      player1: {
        id: string;
        firstName: string;
        lastName: string;
        country: string;
      };
      entryType: string;
    };
  };
  matchType: string;
  eventId: string;
  date: string;
  status: DDETennisEventStatus;
  round: string;
  competitionId: string;
  courtId: string;
};

export type DDETennisLiveEvent = {
  tournamentName: string;
  matchDescription: string;

  tournamentId: string;
  competitionId: string;
  eventId: string;
  sceneURL: string;
}
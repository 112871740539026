export const STRINGS = {
  HELLO_MCCS: 'Welcome to MCC Streaming',
  HELLO_MCCS_CAPTION: 'Discover the world of fascinating events',

  DIALOG_TITLE: 'MCC Streaming',
  DIALOG_CANCEL: 'Cancel',
  DIALOG_YES: 'Yes',
  DIALOG_NO: 'No',

  FORM_EMAIL: 'Email',
  FORM_EMAIL_REQUIRED: 'Email is required',
  FORM_EMAIL_INVALID: 'Email format is invalid',
  FORM_PASSWORD: 'Password',
  FORM_PASSWORD_REQUIRED: 'Password is required',

  THEME: 'Theme',
  THEME_DARK: 'Dark',
  THEME_LIGHT: 'Light',

  SIGN_IN: 'Sign in',
  CREATE: 'Create',
  SAVE: 'Save',

  ADD: 'Add',
  DELETE: 'Delete',

  PAGE_NOT_FOUND_CAPTION: 'Page is not found on this server',
  PAGE_NOT_FOUND_HEADER: '404 Page not found',

  GENERIC_ERROR: 'Something went wrong',
  SIGN_IN_WRONG_CREDENTIALS:
    'Email address or password are incorrect. If you have login problems, please contact technical support.',
};

import { showSnackMessage } from '@/components/SnackMessage/SnackMessage';
import { apiURL, fetchData } from '@/utils/fetchData';
import { StateCreator } from 'zustand';
import { UserSlice } from './UserSlice';
import { Sport } from '@/types/sport';

export type SportsSlice = {
    sports?: Sport[],
    featchSports: () => void;
};

export const createSportsSlice: StateCreator<
    SportsSlice & UserSlice,
    [['zustand/devtools', never]],
    [],
    SportsSlice
> = (set, get) => ({
    featchSports: async () => {
        await fetchData<{ sports: Sport[] }>({
            token: get().token,
            url: apiURL(`/sports`),
        })
            .then(({ sports }) => {
                set(
                    { sports },
                    false,
                    'PDS screens updated',
                );
            })
            .catch((err) => {
                showSnackMessage('Sports', `Error while featching sports list ${err}`);
            });
    },
});

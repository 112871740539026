import { Theme } from "@emotion/react";
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack, SxProps, Typography } from "@mui/material";
import { useMemo } from "react";

type PossibleValues = string | number;

type MCComboProps<T extends PossibleValues> = {
    label: string;
    options?: Record<string, T>;
    value?: T;
    onChange: (value: T) => void;
    style?: SxProps<Theme>
}

export const MCCombo = <T extends PossibleValues = string,>({ label, options, value, onChange, style }: MCComboProps<T>) => {

    const formStyle: SxProps<Theme> = { minWidth: '25ch', ...style };

    const valueExists = useMemo(() => {
        if (!options || !value || value === '') return false;
        return Object.values(options).includes(value);
    }, [options, value])

    const items = useMemo(() => {
        if (!options) return null;
        return Object.keys(options).map((key) => {
            return <MenuItem key={key} value={options[key]}>{key}</MenuItem>
        });
    }, [options])

    return <Stack>
        <FormControl sx={formStyle}>
            <InputLabel>{label}</InputLabel>
            <Select
                autoWidth
                size='small'
                value={valueExists ? value : ''}
                label='Project'
                disabled={!options}
                startAdornment={options ? null : <CircularProgress size={20} />}
                onChange={(ev) => {
                    onChange(ev.target.value as T);
                }}
            >
                {items}
            </Select>
        </FormControl>
        {valueExists || !options ? null : <Typography color="error" variant="caption">{label} not found</Typography>}
    </Stack>
}
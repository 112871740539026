import { StateCreator } from 'zustand';

export type UserSlice = {
  userId?: string;
  token?: string;
  name?: string;
  email?: string;

  setUser: (id: string, token: string, name?: string, email?: string) => void;
};

export const createUserSlice: StateCreator<
  UserSlice,
  [['zustand/devtools', never]],
  [],
  UserSlice
> = (set) => ({
  setUser(userId, token, name, email) {
    set({ userId, token, name, email }, false, 'USR User set');
  },
});

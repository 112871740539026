import { usePersistStore, useStore } from '@/store/Store';
import { DDETennisEvent, DDETournament } from '@/types/dde';
import { MccFixtureInfo, MccProject } from '@/types/mcc';
import { apiURL, fetchData } from '@/utils/fetchData';
import { Button, Grid, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { PreviewScene } from '../../PreviewScene/PreviewScene';
import {
  ProjectSelector
} from '../../SequenceBuilderView/ProjectSelector/ProjectSelector';
import {
  ScreenSelector
} from '../../SequenceBuilderView/ScreenSelector/ScreenSelector';

type Props = {
  tournamentId: DDETournament['identifier'];
  competitionId: string;
  event: DDETennisEvent;
  mccData: MccFixtureInfo;
};

export const TennisEventContent: React.FC<Props> = ({
  tournamentId,
  competitionId,
  event,
  mccData,
}) => {
  const token = useStore(useShallow((state) => state.token));
  const { lastProjectId, lastScreenId, setLastProjectId, setLastScreenId } = usePersistStore(useShallow((state) => ({
    lastProjectId: state.lastProjectId,
    lastScreenId: state.lastScreenId,
    setLastProjectId: state.setLastProjectId,
    setLastScreenId: state.setLastScreenId,
  })));
  const featchProjects = useStore(useShallow((state) => state.featchProjects));
  useEffect(() => {
    featchProjects();
  }, [featchProjects]);

  const [currentProjectId, setCurrentProjectId] = useState<MccProject['_id']>(lastProjectId || '');
  const [currentScreenId, setCurrentScreenId] = useState<MccProject['_id']>(lastScreenId || '');

  useEffect(() => {
    if (currentProjectId) {
      setLastProjectId(currentProjectId);
      setLastScreenId('');
    }
  }, [currentProjectId, setLastProjectId, setLastScreenId]);

  useEffect(() => {
    if (currentScreenId) {
      setLastScreenId(currentScreenId);
    }
  }, [currentScreenId, setLastScreenId]);

  const screens = useStore(useShallow((state) => state.screens));
  const screen = useMemo(() => {
    if (currentProjectId && screens?.has(currentProjectId)) {
      const screen = screens
        .get(currentProjectId)
        ?.find((screen) => screen._id === currentScreenId);
      if (screen) return screen;
    }
    return undefined;
  }, [currentProjectId, currentScreenId, screens]);

  const handleSendAction = (designId: string) => {
    const data = {
      containerId: 0,
      project: currentProjectId,
      designId,
    };
    fetchData({
      token,
      url: apiURL(
        `/stream/tennis/tournaments/${tournamentId}/competitions/${competitionId}/play/${event.eventId}/design`,
      ),
      method: 'POST',
      data,
    })
      .then(() => {
        enqueueSnackbar('Design Change Send', { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: 'error' });
      });
  };

  return (
    <>
      <Stack sx={{ flexDirection: { sm: 'column', md: 'row', zIndex: 10 } }} gap={2} flex={1} overflow='hidden'>
        <Stack flex={0.3} sx={{ height: '100%', overflowY: 'auto' }}>
          <ProjectSelector currentProjectId={currentProjectId} setCurrentProjectId={setCurrentProjectId} />
          <ScreenSelector currentProjectId={currentProjectId} currentScreenId={currentScreenId} setCurrentScreenId={setCurrentScreenId} />
          <Grid container spacing={1} p={1}>
            {screen &&
              screen.designs.map((design) => {
                return (
                  <Grid item key={design._id} xs={4} md={6} lg={4}>
                    <Button
                      variant='outlined'
                      color='info'
                      onClick={() => {
                        handleSendAction(design._id);
                      }}
                      sx={{ width: '100%', aspectRatio: { xs: 'auto', md: '1/1' } }}
                      title={design.name}
                    >
                      <Typography
                        sx={{
                          width: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                        variant='caption'
                      >
                        {design.name}
                      </Typography>
                    </Button>
                  </Grid>
                );
              })}
          </Grid>
        </Stack>
        <Box flex={0.7}>
          <PreviewScene mccData={mccData} />
        </Box>
      </Stack>
    </>
  );
};

import { Store, useStore } from '@/store/Store';
import { MccProject } from '@/types/mcc';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { SCREEN_SELECTOR_SP } from '../ScreenSelector/ScreenSelector';

const socketSelector = (store: Store) => ({
  projects: store.projects,
});

type Props = {
  currentProjectId?: MccProject['_id'];
  setCurrentProjectId?: (projectId: MccProject['_id']) => void;
};

export const PROJECT_SELECTOR_SP = 'selectedProject';

export const ProjectSelector: React.FC<Props> = ({ currentProjectId, setCurrentProjectId }) => {
  const { projects } = useStore(socketSelector);
  const [serachParams, setSearchParams] = useSearchParams();

  let projectId: MccProject['_id'] | null;
  if (currentProjectId) {
    projectId = currentProjectId;
  } else {
    projectId = serachParams.get(PROJECT_SELECTOR_SP);
  }

  const handleChange = (event: SelectChangeEvent) => {
    if (setCurrentProjectId) {
      setCurrentProjectId(event.target.value as MccProject['_id']);
    } else {
      setSearchParams((prev) => {
        prev.set(PROJECT_SELECTOR_SP, event.target.value);
        prev.delete(SCREEN_SELECTOR_SP);
        return prev;
      });
    }
  };

  return (
    <>
      <FormControl
        sx={{ m: 1, minWidth: '20%' }}
        size='small'
        disabled={!projects || projects.length === 0}
      >
        <InputLabel>Project</InputLabel>
        <Select
          autoWidth
          value={projectId && projects ? projectId : ''}
          label='Project'
          onChange={handleChange}
        >
          {projects?.map((item) => (
            <MenuItem key={item._id} value={item._id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
